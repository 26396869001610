import React, { ReactElement, useEffect } from 'react';
import { ReactComponent as CloseImg } from '../../assets/close.svg';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import style from './popUp.module.css';

type PopUpProps = {
  children: any;
  title: string;
  close: () => void;
  isOpen: boolean;
};

export const PopUp = (props: PopUpProps): ReactElement => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { title, isOpen } = props;

  if (isOpen) {
    return (
      <div className={`${style.greyBackGround} darkGrey `}>
        <div className={`${style.box} white `}>
          <div className={`${style.box__header} mainColor `}>
            <h2 className={`${style.box__title} textColorWhite `}>{title}</h2>
            <div className={style.btn}>
              <GenericButton
                icon={() => <CloseImg className={`${style.closeImg}`} />}
                title=""
                clickHandler={() => props.close()}
              />
            </div>
          </div>
          <div className={style.itemBox}>{props.children}</div>
        </div>
      </div>
    );
  }
  return <></>;
};
