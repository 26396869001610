import React, { useEffect, useState } from 'react';
import { PassPrice } from '../../../common_lib_front/hooks/useOrderPrice';
import { PassInfoData } from '../../../utils/useVehicleConfigs';
import style from './getPasses.module.css';
import { VehiclePass } from './types';
import { PassOptionErrorsType } from './validateVehicleData';
import VehiclePassForm from './vehiclePassForm';

interface PassOptionsProps {
  passInfoId: PassInfoData[];
  vehiclePassesByType: { [passInfoId: string]: VehiclePass[] };
  passPriceInfo: { [passInfoId: string]: PassPrice };
  days: number;
  startDate: string;
  endDate: string;
  isGuestLink: boolean | undefined;
  errors?: PassOptionErrorsType;
  addVehiclePass: (passInfoId: string) => void;
  removeVehiclePass: (passInfoId: string) => void;
  handleVehicleChange: (
    passInfoId: string,
    index: number,
    field: keyof VehiclePass,
    value: string,
  ) => void;
  handleCheckboxChange: (passInfoId: string, index: number) => void;
}

const PassOptions: React.FC<PassOptionsProps> = ({
  passInfoId,
  vehiclePassesByType,
  passPriceInfo,
  days,
  startDate,
  endDate,
  isGuestLink,
  errors,
  addVehiclePass,
  removeVehiclePass,
  handleVehicleChange,
  handleCheckboxChange,
}) => {
  const [newStartDate, setNewStartDate] = useState(startDate);
  useEffect(() => {
    setNewStartDate(startDate);
  }, [startDate]);
  const filteredPassInfoIds = passInfoId.filter(passInfoData => {
    // Check for missing fields or incorrect structure
    const duration = passInfoData?.durationInfo?.duration1;

    let isMatchingPass = false;

    // Add more specific logging to understand why a condition is not met

    if (typeof duration === 'undefined' || duration === null) {
      console.log(
        `Duration is missing or invalid for pass ID: ${passInfoData.passInfoId || 'N/A'}`,
      );
    } else if (!startDate || !endDate) {
      isMatchingPass = passInfoData.portal === 'guest';
    } else if (days <= 2 && startDate && endDate) {
      isMatchingPass = passInfoData.portal === 'guest' && duration < 2;
    } else if (days > 2 && days < 180 && startDate && endDate) {
      isMatchingPass = passInfoData.portal === 'guest' && duration < 180;
    } else if (days >= 180 && startDate && endDate) {
      isMatchingPass = passInfoData.portal === 'guest';
    }

    if (isMatchingPass) {
    } else {
    }

    return isMatchingPass;
  });

  const getDefaultDates = (passInfoData: PassInfoData) => {
    const currentDate = new Date().toISOString().split('T')[0];
    let maxEndDate;
    if (startDate) {
      maxEndDate = new Date(startDate);
    } else {
      maxEndDate = new Date();
    }
    maxEndDate.setDate(
      maxEndDate.getDate() + (passInfoData?.durationInfo?.duration1 || 0),
    );
    const maxEndDateString = maxEndDate.toISOString().split('T')[0];
    const defaultStartDate = startDate || currentDate;
    const defaultEndDate =
      endDate && new Date(endDate) <= maxEndDate ? endDate : maxEndDateString;
    return { defaultStartDate, defaultEndDate, maxEndDateString };
  };

  return (
    <>
      {filteredPassInfoIds.map(passInfo => {
        const passInfoId = passInfo.passInfoId;
        const vehiclePasses = vehiclePassesByType[passInfoId] || [];
        const { defaultStartDate, defaultEndDate, maxEndDateString } =
          getDefaultDates(passInfo);
        return (
          <div key={passInfoId} className={`${style.mainInnerBox}`}>
            <div className={style.innerBox}>
              <div className={style.passOptions}>
                <div className={style.passHeader}>
                  <h2>{passInfo.name}</h2>
                  <div className={style.row}>
                    <div>
                      <p style={{ margin: '10px 0px 0px 0px' }}>{passInfo.description}</p>
                    </div>
                    <div className={style.passCounter}>
                      {isGuestLink && (
                        <div className={style.priceContainer}>
                          <span className={style.price}>
                            ${passPriceInfo[passInfoId]?.price || 0}/pass
                          </span>
                        </div>
                      )}
                      <div className={style.counterContainer}>
                        <button
                          type="button"
                          className={style.counterButton}
                          onClick={() => removeVehiclePass(passInfoId)}
                          disabled={vehiclePasses.length <= 0}
                        >
                          -
                        </button>
                        <span className={style.counter}>{vehiclePasses.length}</span>
                        <button
                          type="button"
                          className={style.counterButton}
                          onClick={() => addVehiclePass(passInfoId)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {vehiclePasses.map((vehiclePass, index) => (
                  <VehiclePassForm
                    key={index}
                    index={index}
                    vehiclePass={vehiclePass}
                    passInfoId={passInfoId}
                    errors={errors}
                    handleChange={(index, field, value) =>
                      handleVehicleChange(passInfoId, index, field, value)
                    }
                    handleCheckboxChange={index =>
                      handleCheckboxChange(passInfoId, index)
                    }
                    smsAlert={false}
                    duration={passInfo.durationInfo?.duration1}
                    initialStartDate={startDate}
                    initialEndDate={endDate}
                    maxEndDate={maxEndDateString}
                    promotionalAlert={false}
                    setSmsAlert={function (): void {
                      throw new Error('Function not implemented.');
                    }}
                    setPromotionalAlert={function (): void {
                      throw new Error('Function not implemented.');
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default PassOptions;
