import React, { ReactElement } from 'react';
import tabStyle from './myPassesTab.module.css';

interface MyPassesTabProps {
  clickHandler: () => void;
  title: string;
  active: boolean;
  highlightOnFocus?: boolean;
}

export const MyPassesTab = (props: MyPassesTabProps): ReactElement => {
  const { clickHandler, title, highlightOnFocus } = props;
  let clickedStyle = 'lightGrey';
  let clickedTextStyle = 'textColorDark';
  if (highlightOnFocus) {
    // CSS FOR WHEN CLICKED
    clickedStyle = 'thirdColor';
    clickedTextStyle = 'textColorThird';
  }
  return (
    <div className={`${tabStyle.tabBox}  `}>
      <button
        className={`${tabStyle.tabBox__tab} ${
          clickedTextStyle === 'textColorThird' ? '' : tabStyle.active
        } white ${clickedTextStyle}`}
        onClick={clickHandler}
      >
        <div className={tabStyle.tabBox__titleBox}>
          <h4 className={tabStyle.tabBox__title}>{title}</h4>
        </div>
        <div className={`${tabStyle.tabBox__tabLine}  ${clickedStyle}`} />
      </button>
    </div>
  );
};

MyPassesTab.defaultProps = {
  highlightOnFocus: false,
};

export default MyPassesTab;
