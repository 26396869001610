import { gql, useMutation } from '@apollo/client';
import { backendResponse } from './../common_lib_front/types/backendResponse';

export const INVITE_GUEST = gql`
  mutation Mutation($invites: [InviteGuestInput!]!, $propertySlug: String!) {
    inviteGuests(invites: $invites, propertySlug: $propertySlug) {
      error
      success
      data {
        registration {
          registrationId
        }
        passes {
          passId
          passInfoId
        }
      }
    }
  }
`;

export type INVITE_GUEST_VARS = {
  invites: {
    passes: Array<{
      startDate: string;
      endDate: string;

      passInfoId: string;
    }>;
    rentalInfo: {
      address: string;
      arrivalDate: string;
      departureDate: string;
    };
    guestInfo: {
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string;
    };
    hostWillPay?: boolean;
  }[];
  propertySlug: string;
  cancelInvitNotificaion: boolean;
};

export type INVITE_GUEST_RES = {
  inviteGuests: backendResponse<
    {
      registration?: {
        registrationId?: string;
      };
      passes?: {
        passId?: string;
        passInfoId?: string;
      }[];
    }[]
  >;
};

export function useInviteGuest() {
  const [doInviteGuest, { data, loading, error }] = useMutation<
    INVITE_GUEST_RES,
    INVITE_GUEST_VARS
  >(INVITE_GUEST);

  const inviteGuests = async (
    invites: INVITE_GUEST_VARS['invites'],
    propertySlug: INVITE_GUEST_VARS['propertySlug'],
    cancelInvitNotificaion: INVITE_GUEST_VARS['cancelInvitNotificaion'],
  ) => {
    try {
      const response = await doInviteGuest({
        variables: { invites, propertySlug, cancelInvitNotificaion },
      });
      return response.data;
    } catch (err) {
      console.error('Error inviting guests:', err);
      throw err;
    }
  };

  return {
    inviteGuests,
    data,
    loading,
    error,
  };
}
