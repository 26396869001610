import React, { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import GenericButton from '../../components/genericButton/genericButton';
import TitledStection from '../../components/titledSection/titledSection';
import style from '../privacyPolicy/privacyPolicy.module.css';

export default function TermsAndConditions(): ReactElement {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const history = useHistory();
  const highlightFirst = (elem: string) => {
    let [p, d] = ['', ''];
    if (elem.includes('|||')) {
      [p, d] = elem.split('|||');
    } else {
      const idx = elem.indexOf(' ');
      [p, d] = [elem.slice(0, idx), elem.slice(idx)];
    }
    return (
      <li key={p} className={`${style.listNoLine} textColorSuperDark`}>
        <strong className="textColorMain">{p}</strong>
        {d}
      </li>
    );
  };
  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <div className={`${style.subHeader} mainColor `}>
          <div className={style.textBox}>
            <div className={`${style.circle} white `} />
            <h1 className={`${style.subHeaderTitle} textColorWhite `}>
              Terms and Conditions
            </h1>
          </div>
          <span />
          <div className={style.backBtn}>
            <GenericButton
              color="yellow"
              size="large"
              // size="auto"
              title="Back"
              clickHandler={history.goBack}
            />
          </div>
        </div>
        <div className={style.infoBox}>
          <h3 className={style.update}>Last updated: March 08, 2021</h3>
          <h3 className={style.catelogue}>Catelogue:</h3>
          <ul className={style.catalogueBox}>
            {[
              'Interpretation and Definitions',
              'Acknowledgment',
              'Intellectual Property',
              'Links to Other Websites',
              'Termination',
              'Limitation of Liability',
              '"AS IS" and "AS AVAILABLE" Disclaimer',
              'Indemnity',
              'Governing Law',
              'Disputes Resolution',
              'For European Union (EU) Users',
              'United States Legal Compliance',
              'Severability and Waiver',
              'Translation Interpretation',
              'Changes to These Terms and Conditions',
              'Contact Us',
            ].map((e: string) => (
              <li key={e} className={style.list}>
                {e}
              </li>
            ))}
          </ul>
          <h4 className={`${style.about} textColorSuperDark`}>
            Please read these terms and conditions carefully before using Our Service
          </h4>
          <h2 className={style.title}>Interpretations and Definitions</h2>
          <h4 className={style.subtitle}>Interpretation</h4>
          <p className={`${style.text} textColorSuperDark`}>
            The words of which the initial letter is capitalized have meanings defined
            under the following conditions. The following definitions shall have the same
            meaning regardless of whether they appear in singular or in plural.
          </p>
          <h4 className={style.subtitle}>Definitions</h4>
          <p className={`${style.text} textColorSuperDark`}>
            For the purposes of these Terms and Conditions
          </p>
          {[
            'Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.',
            'Country and State||| refers to: United States and Utah, respectively.',
            'Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to SymLiv, LLC, 69 N Paradise Parkway BLDG B STE 224 Garden City, UT 84028',
            'Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.',
            'Service refers to the Website.',
            'Terms and Conditions||| (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service. This Terms and Conditions agreement has been created with the help of the Terms and Conditions Generator.',
            'Third-party Social Media Service||| means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.',
            'Website refers to Symliv, accessed via https://symliv.com/',
            'You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.',
          ].map(highlightFirst)}
          <TitledStection
            title="Acknowledgment"
            textContent={[
              'These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.',
              'Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.',
              'By accessing or using the Service, You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.',
              'You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.',
              'Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.',
            ]}
          />
          <TitledStection
            title="Intellectual Property"
            textContent={[
              'This Website, the content, any materials downloaded, and all intellectual property pertaining to or contained on the Website (including but not limited to copyrights, patents, database rights, graphics, designs, text, logos, trade dress, trademarks and service marks) are owned by the Company, its affiliates or third parties and all right, title and interest therein shall remain the property of the Company, its affiliates and/or such third parties (collectively, the "Content"). All Content is protected by trade dress, copyright, patent and trademark laws, and various other intellectual property rights and unfair competition laws.',
              'You are authorized solely to view and retain a copy of the pages of the Website for your own personal, non-commercial use. You may also view and make copies of relevant documents, pages, images or other materials on this Site for the purpose of transacting business with the Company. You agree that you will not duplicate, publish, modify, create derivative works from, participate in the transfer of, post on the internet, or in any way distribute or exploit the Site, or any portion of the Website for any public or commercial use without the express written consent of the Company. Additionally, you agree that you will not (i) remove or alter any author, trademark or other proprietary notice or legend displayed on the Website (or printed pages produced from the Website); and (ii) make any other modifications to any documents obtained from the Website other than in connection with completing information required to transact business with the Company.  You acknowledge and agree that the Company can use feedback or suggestions provided by You for its own purposes without compensation to You.',
            ]}
          />
          <TitledStection
            title="Links to Other Websites"
            textContent={[
              'Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.',
              'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.',
              'We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.',
            ]}
          />
          <TitledStection
            title="Termination"
            textContent={[
              'We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.',
              'Upon termination, Your right to use the Service will cease immediately.',
            ]}
          />
          <TitledStection
            title="Limitation of Liability"
            textContent={[
              "Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.",
              'To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.',
              "Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.",
            ]}
          />
          <TitledStection
            title='"AS IS" and "AS AVAILABLE" Disclaimer'
            textContent={[
              'The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.',
              "Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.",
              'Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.',
            ]}
          />
          <TitledStection
            title="Indemnity"
            textContent={[
              'By using the Website, you agree to defend, indemnify, and hold harmless the Company from and against any and all losses, claims, damages, costs and expenses (including reasonable legal and accounting fees) that the Company may become obligated to pay arising or resulting from your use of this Website, the Content, the Services, or your breach of these Terms and Conditions. The Company reserves the right to assume or participate, at your expense, in the investigation, settlement and defense of any such action or claim.',
            ]}
          />
          <TitledStection
            title="Governing Law"
            textContent={[
              'To the maximum extent permitted by law, these Terms and Conditions are governed by the laws of the State and you hereby consent to the exclusive jurisdiction and venue of courts in the state and federal courts in Salt Lake County, Utah in all disputes arising out of the use of the Website.',
            ]}
          />
          <TitledStection
            title="For European Union (EU) Users"
            textContent={[
              'If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.',
            ]}
          />
          <TitledStection
            title="United States Legal Compliance"
            textContent={[
              'You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.',
            ]}
          />
          <TitledStection title="" textContent={['']} />
          <TitledStection
            title="Translation Interpretation"
            textContent={[
              'These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.',
            ]}
          />
          <TitledStection
            title="Translation Interpretation"
            textContent={[
              'These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.',
            ]}
          />
          <TitledStection
            title="Changes to These Terms and Conditions"
            textContent={[
              "We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material, We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.",
              'By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.',
            ]}
          />
          <TitledStection
            title="Contact Us"
            textContent={[
              'If you have any questions about these Terms and Conditions, You can contact Us:',
            ]}
            listContent={[
              'By visiting this page on our website: https://symliv.com/contact-sales/',
            ]}
          />
        </div>
      </div>
    </div>
  );
}
