import { gql } from '@apollo/client';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';

const CREATE_CUSTOMER = gql`
  mutation createStripeCustomer($userId: String!) {
    createStripeCustomer(userId: $userId) {
      success
      data {
        stripeCustomerId
      }
      error
    }
  }
`;

type CREATE_CUSTOMER_VARS = {
  userId: string;
};

type CREATE_CUSTOMER_RES = {
  createStripeCustomer: any;
};

export function mutateWrapperForCreateCustomer(
  data: CREATE_CUSTOMER_VARS,
): Promise<CREATE_CUSTOMER_RES> {
  return backendClient
    .mutate<CREATE_CUSTOMER_RES, CREATE_CUSTOMER_VARS>({
      mutation: CREATE_CUSTOMER,
      variables: data,
    })
    .then((res: any) => res.data);
}
