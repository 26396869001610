import { gql } from '@apollo/client';
import { useCallback, useState } from 'react';
import { vehicleFormData } from '../../components/vehicleInfoForm/vehicleInfoForm';
import { backendResponse } from '../../types/backendResponse';
import { backendClient } from '../../utilities/BackendAPI';

const EDIT_VEHICLE = gql`
  mutation Mutation(
    $vehicleId: String!
    $destination: String
    $licensePrimaryDriver: String
    $primaryDriverName: String
    $licensePlateState: String
    $fleetNumber: String
    $isRental: Boolean
    $licensePlate: String
    $color: String
    $type: String
    $vehicleModel: String
    $make: String
    $year: Float
    $registrationDoc: String
    $insuranceDoc: String
    $regDocFileName: String
    $insDocFileName: String
  ) {
    editCompanyVehicle(
      vehicleId: $vehicleId
      destination: $destination
      licensePrimaryDriver: $licensePrimaryDriver
      primaryDriverName: $primaryDriverName
      licensePlateState: $licensePlateState
      fleetNumber: $fleetNumber
      isRental: $isRental
      licensePlate: $licensePlate
      color: $color
      type: $type
      vehicleModel: $vehicleModel
      make: $make
      year: $year
      registrationDoc: $registrationDoc
      insuranceDoc: $insuranceDoc
      regDocFileName: $regDocFileName
      insDocFileName: $insDocFileName
    ) {
      success
      error
    }
  }
`;

const ADD_VEHICLE = gql`
  mutation RegisterCompanyVehicle(
    $make: String
    $vehicleModel: String
    $type: String
    $color: String
    $licensePlate: String
    $fleetNumber: String
    $isRental: Boolean
    $primaryDriverName: String
    $licensePlateState: String
    $licensePrimaryDriver: String
    $destination: String
    $year: Float
    $registrationDoc: String
    $insuranceDoc: String
    $regDocFileName: String
    $insDocFileName: String
  ) {
    registerCompanyVehicle(
      make: $make
      vehicleModel: $vehicleModel
      type: $type
      color: $color
      licensePlate: $licensePlate
      fleetNumber: $fleetNumber
      isRental: $isRental
      primaryDriverName: $primaryDriverName
      licensePlateState: $licensePlateState
      licensePrimaryDriver: $licensePrimaryDriver
      destination: $destination
      year: $year
      registrationDoc: $registrationDoc
      insuranceDoc: $insuranceDoc
      regDocFileName: $regDocFileName
      insDocFileName: $insDocFileName
    ) {
      error
      success
      data {
        vehicleId
      }
    }
  }
`;

type ADD_VEHICLE_VARS = {
  make?: string;
  vehicleModel?: string;
  type?: string;
  color?: string;
  licensePlate?: string;
  fleetNumber?: string;
  isRental?: boolean;
  primaryDriverName?: string;
  licensePlateState?: string;
  licensePrimaryDriver?: string;
  destination?: string;
  year?: number;
  registrationDoc?: string;
  insuranceDoc?: string;
  regDocFileName?: string;
  insDocFileName?: string;
};

type EDIT_VEHICLE_VARS = {
  vehicleId: string;
  destination?: string;
  licensePrimaryDriver?: string;
  primaryDriverName?: string;
  licensePlateState?: string;
  fleetNumber?: string;
  isRental?: boolean;
  licensePlate?: string;
  color?: string;
  type?: string;
  vehicleModel?: string;
  make?: string;
  year?: number;
  registrationDoc?: string;
  insuranceDoc?: string;
  regDocFileName?: string;
  insDocFileName?: string;
};

type ADD_VEHICLE_RES = {
  registerCompanyVehicle: backendResponse<{vehicleId:string}>;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isAddVehicleRes(obj: any): obj is ADD_VEHICLE_RES {
  return typeof obj?.registerCompanyVehicle?.success === 'boolean';
}
// dummy type for now
type EDIT_VEHICLE_RES = {
  editCompanyVehicle: backendResponse<undefined>;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isEditVehicleRes(obj: any): obj is EDIT_VEHICLE_RES {
  return typeof obj?.editCompanyVehicle?.success === 'boolean';
}

type useAddEditVehicleRes = {
  submit: (data: vehicleFormData) => Promise<ADD_VEHICLE_RES | EDIT_VEHICLE_RES>;
  loading: boolean;
  error?: string;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isEditVars(inpt: any): inpt is EDIT_VEHICLE_VARS {
  return !!inpt.vehicleId;
}

function mutateWrapper(
  data: EDIT_VEHICLE_VARS | ADD_VEHICLE_VARS,
): Promise<EDIT_VEHICLE_RES | ADD_VEHICLE_RES | null | undefined> {
  if (isEditVars(data)) {
    return backendClient
      .mutate<EDIT_VEHICLE_RES, EDIT_VEHICLE_VARS>({
        mutation: EDIT_VEHICLE,
        variables: data,
      })
      .then(res => res.data);
  }
  return backendClient
    .mutate<ADD_VEHICLE_RES, ADD_VEHICLE_VARS>({
      mutation: ADD_VEHICLE,
      variables: data,
    })
    .then(res => res.data);
}

const fileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      }
    };
    reader.onerror = () => {
      reject();
    };
    reader.readAsDataURL(file);
  });

export default function useAddEditVehicle(): useAddEditVehicleRes {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const submit: useAddEditVehicleRes['submit'] = useCallback(
    async (data: vehicleFormData) => {
      const insuranceDoc = data.insuranceFile && (await fileToBase64(data.insuranceFile));
      const registrationDoc =
        data.registrationFile && (await fileToBase64(data.registrationFile));
      setLoading(true);
      return mutateWrapper({
        vehicleId: data.vehicleId,
        destination: data.destination,
        licensePrimaryDriver: data.licensePrimaryDriver,
        primaryDriverName: data.primaryDriverName,
        licensePlateState: data.licensePlateState,
        fleetNumber: data.fleetNumber,
        isRental: data.isRental,
        licensePlate: data.plateNumber,
        color: data.color,
        type: data.type,
        vehicleModel: data.model,
        make: data.make,
        year: data.year,
        insuranceDoc: insuranceDoc || undefined,
        registrationDoc: registrationDoc || undefined,
        regDocFileName: data.regDocFileName || '',
        insDocFileName: data.insDocFileName || '',
      })
        .then(res => {
          if (!res) {
            throw Error('Result does not exist. Something went wrong');
          }
          if (isAddVehicleRes(res) && res.registerCompanyVehicle.error) {
            setError(res.registerCompanyVehicle.error);
            throw res.registerCompanyVehicle.error;
          } else if (isEditVehicleRes(res) && res.editCompanyVehicle.error) {
            setError(res.editCompanyVehicle.error);
            throw res.editCompanyVehicle.error;
          } else {
            setError(undefined);
          }
          if (isAddVehicleRes(res) && res.registerCompanyVehicle?.data?.vehicleId) {
            sessionStorage.setItem('vehicleId', res.registerCompanyVehicle?.data?.vehicleId);
          }
          return res;
        })
        .catch(res => {
          setError(
            typeof res === 'string'
              ? res
              : 'Something went wrong. Changes could not be saved.',
          );
          // return res;
          throw res;
        })
        .finally(() => setLoading(false));
    },
    [setLoading],
  );

  return {
    loading,
    submit,
    error,
  };
}
