import { VehicleData, VehiclePass } from './types';
interface VehicleConfig {
  [key: string]: {
    [field: string]: 'required' | 'optional' | 'disabled';
  };
}

export type PassOptionErrorsType = {
  [key: string]: {
    [innerKey: string]: string[];
  };
};

export function validateVehicleData(
  vehicleData: VehicleData,
  vehicleConfig: VehicleConfig,
): { valid: boolean; errors?: PassOptionErrorsType; message?: string } {
  // Define the fields that should be validated if marked as "required"
  const requiredFields = [
    'make',
    'vehicleModel',
    'type',
    'color',
    'licensePlate',
    'fleetNumber',
    'licensePlateState',
    'primaryDriverName',
    'licensePrimaryDriver',
  ];

  const errors: PassOptionErrorsType = {};
  let hasError = false;

  // Loop through each passInfoId in the vehicleData
  for (const passInfoId in vehicleData) {
    errors[passInfoId] = {};
    if (vehicleData.hasOwnProperty(passInfoId)) {
      const vehicles = vehicleData[passInfoId];

      // Retrieve the configuration for this specific passInfoId
      const config = vehicleConfig[passInfoId] || {};

      vehicles.forEach((vehicle, i) => {
        errors[passInfoId][i] = [];
        if (!vehicle.dontKnowLicense) {
          // Iterate through the specified fields and validate if marked as "required" in the config
          for (const field of requiredFields) {
            if (config[field] === 'required') {
              // Check if the field exists in the vehicle data and is not empty
              const value = vehicle[field as keyof VehiclePass];
              if (!value || value === '') {
                // Create a user-friendly field name by replacing camelCase with spaces and capitalizing
                // const readableFieldName = field.replace(/([A-Z])/g, ' $1').toLowerCase();
                errors[passInfoId][i].push(field);
                hasError = true;
                // return { valid: false, message: `Please fill in all required fields. Missing: ${readableFieldName}` };
              }
            }
          }
        }
      });
    }
  }
  if (hasError) {
    return { valid: false, errors };
  }

  return { valid: true };
}
