import { gql, useMutation, useQuery } from '@apollo/client';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SaveIcon from '@mui/icons-material/Save';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import { ColumnState } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import * as React from 'react';
import { RefObject, useCallback } from 'react';
import store from '../../common_lib_front/utilities/store';
import { GridColumnInput } from '../hooks/useUsers';
import { useUsers } from '../hooks/useUsers';

const SAVE_GRID_SETTINGS_MUTATION = gql`
  mutation saveGridSettings($gridSettingInput: GridSettingInput!) {
    saveGridSettings(gridSettingInput: $gridSettingInput) {
      success
      error
    }
  }
`;

const REFRESH_USER_INFORMATION_QUERY = gql`
  query GetUserInformation($userId: String) {
    getUserInformation(userId: $userId) {
      success
      data {
        userId
        gridSettings {
          grids {
            gridName
            columns {
              aggFunc
              colId
              flex
              hide
              pinned
              pivot
              pivotIndex
              rowGroup
              rowGroupIndex
              sort
              sortIndex
              width
            }
          }
        }
      }
      error
    }
  }
`;

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

interface Props {
  referenceGrid: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  agGridRef: RefObject<AgGridReact<any>>;
  onAlert: (someAlert: any) => void;
}
export default function GridColumnOrderMenu({
  agGridRef,
  referenceGrid,
  onAlert,
}: Props) {
  const [user, setUser] = React.useState<any>(store.getState().user);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { getCurrentUserInformation } = useUsers();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //const snackbar = useSnackBar();
  const [saveGridSettings] = useMutation(SAVE_GRID_SETTINGS_MUTATION);

  const restoreState = useCallback(async () => {
    const userInfo = await getCurrentUserInformation();
    const gridSettings = userInfo?.gridSettings?.grids.filter(
      (grid: { gridName: string }) => grid.gridName === 'my-passes',
    );
    if (!gridSettings || !gridSettings?.[0]) {
      return;
    }

    if (gridSettings || gridSettings?.[0]) {
      const currentColState = gridSettings?.[0];
      agGridRef.current!.columnApi.applyColumnState({
        state: currentColState.columns as unknown as ColumnState[],
        applyOrder: true,
      });
    }
  }, []);

  const handleSaveColumnSettings = useCallback(async () => {
    const currentColState = agGridRef.current!.columnApi?.getColumnState();
    const input = {
      gridName: referenceGrid,
      columns: currentColState as unknown as GridColumnInput[],
    };

    saveGridSettings({
      variables: {
        gridSettingInput: input,
      },
      onCompleted: resp => {
        if (resp.saveGridSettings.success) {
          onAlert({
            color: 'green',
            message: 'Grid column settings have been sucessfuly saved.',
          });
        }
      },
      onError: error => {
        onAlert({
          color: 'red',
          message: error.message,
        });
      },
    });

    setAnchorEl(null);
  }, []);

  const resetColumnSettings = useCallback(async () => {
    const input = {
      gridName: referenceGrid,
      columns: [],
    };
    saveGridSettings({
      variables: {
        gridSettingInput: input,
      },
      onCompleted: resp => {
        if (resp.saveGridSettings.success) {
          onAlert({
            color: 'green',
            message: 'Grid column settings have been sucessfuly saved.',
          });

          if (resp.success === true) {
            //refreshCurrentUserInfo(user.userId);
          }
        }
      },
      onError: error => {
        onAlert({
          color: 'red',
          message: error.message,
        });
      },
    });

    agGridRef.current!.columnApi.resetColumnState();

    onAlert({
      color: 'green',
      message: 'Column settings have been saved sucessfully restored to default values!',
    });
    setAnchorEl(null);
  }, []);

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Column Settings
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSaveColumnSettings} disableRipple>
          <SaveIcon />
          Save Column Order
        </MenuItem>
        <MenuItem onClick={resetColumnSettings} disableRipple>
          <SettingsBackupRestoreIcon />
          Restore Column Order
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
