import { t } from 'i18next';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import ScormWrapper from '../../../common_lib_front/components/scormWrapper/scormWrapper';
import style from './guestCommunityRule.module.css';

export default function GuestCommunityRule(): ReactElement {
  const history = useHistory();
  const { propertySlug } = useParams<{ propertySlug: string }>();
  const { registrationId } = useParams<{ registrationId: string }>();
  const [showButton, setShowButton] = useState(true);
  const { featuresConfig } = useContext(CommunityContext);

  const submitHandler = () => {
    const nextPageURL = `/guest/${propertySlug}/${registrationId}/payment-request`;
    history.replace(nextPageURL);
  };

  useEffect(() => {
    if (featuresConfig && featuresConfig.guest && featuresConfig.guest.educationStepSkippable === false) {
      setShowButton(false);
    }
  }, [featuresConfig]);

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <div className={`${style.passForm} `}>
          <div className={style.headerwraper}>
            <h1 className={`${style.subHeaderTitle} textColorBlack`} >
              Review the community guidelines.
            </h1>
            <div className={style.subHeaderNote}>Please watch the video in order to procceed.</div>
          </div>
          <div className={style.videoBox}>
            <div className={style.videoContainer}>
              <ScormWrapper continueHandler={() => submitHandler()} portal="guest" />
            </div>
          </div>
          <div className={`${style.innerBox} ${style.buttonBox}`}>
            <div className={style.buttonContainer}>
              {(
                showButton ? <button onClick={submitHandler} className={style.button} type="submit">
                  I agree to community guidelines
                </button> : null
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
