import { gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import { backendResponse } from '../types/backendResponse';
import { backendClient } from '../utilities/BackendAPI';
import { getCommunityId } from './getCommunityId';

const ALL_COMMUNITY_FEATURES_FRAGMENMT = gql`
  fragment AllCommunityFeatures on CommunityConfig {
    passFeatures
    gateCode
    host {
      enabled
      validLength
      ownerPinEnabled
      rentalRegistrationLabel
      inviteGuest {
        enabled
        hostMustPay
        propertyLinkEnabled
        multiplePasses
      }
      initialRegistration {
        educationStepEnabled
        educationStepSkippable
        documentStepEnabled
        rentalRegistrationStepEnabled
      }
    }
    resident {
      enabled
      registration {
        educationStepEnabled
        educationStepSkippable
        documentStepEnabled
        ownerPinEnabled
      }
      residentPasses {
        enabled
        educationStepSkippable
      }
      inviteVendor {
        enabled
      }
      familyAndFriendsPasses {
        enabled
        identificationRequired
      }
    }
    isOfficerInsightsEnabled
    legacySingleUsePassEnabled
    protectGuestDoubleCharge
    vendor {
      enabled
      businessTypeRequired
      hideEmployeeMenu
      hideVehicleMenu
      initialRegistration {
        educationByBusinessType
        educationStepSkippable
        autoApprove
      }
    }
    guest {
      educationStepSkippable
    }
  }
`;
export type CommuntiyConfigType = {
  passFeatures?: boolean;
  gateCode?: boolean;
  isOfficerInsightsEnabled?: boolean;
  protectGuestDoubleCharge?: boolean;
  legacySingleUsePassEnabled?: boolean;
  host?: {
    enabled?: boolean;
    validLength?: number;
    ownerPinEnabled?: boolean;
    rentalRegistrationLabel?: string;
    inviteGuest?: {
      enabled?: boolean;
      hostMustPay?: boolean;
      propertyLinkEnabled?: boolean;
      multiplePasses?: boolean;
    };
    initialRegistration?: {
      educationStepEnabled?: boolean;
      educationStepSkippable?: boolean;
      documentStepEnabled?: boolean;
      rentalRegistrationStepEnabled?: boolean;
    };
  };
  resident?: {
    enabled?: boolean;
    registration?: {
      educationStepEnabled?: boolean;
      educationStepSkippable?: boolean;
      documentStepEnabled?: boolean;
      ownerPinEnabled?: boolean;
    };
    residentPasses?: {
      enabled?: boolean;
      educationStepSkippable?: boolean;
    };
    inviteVendor?: {
      enabled?: boolean;
    };
    familyAndFriendsPasses?: {
      enabled?: boolean;
      identificationRequired?: boolean;
    };
  };
  vendor?: {
    enabled?: boolean;
    businessTypeRequired?: boolean;
    hideEmployeeMenu?: boolean;
    hideVehicleMenu?: boolean;
    initialRegistration?: {
      educationByBusinessType?: boolean;
      educationStepSkippable?: boolean;
      autoApprove?: boolean;
    };
  };
  guest?: {
    educationStepSkippable?: boolean;
  };
};

export const GET_ALL_COMMUNITIES = gql`
  ${ALL_COMMUNITY_FEATURES_FRAGMENMT}
  query GetAllCommunities {
    getAllCommunities {
      error
      success
      data {
        communityId
        name
      }
    }
  }
`;
export type GET_ALL_COMMUNITIES_VARS = Record<string, unknown>;
export type GET_ALL_COMMUNITIES_RES = {
  getAllCommunities: backendResponse<
    {
      communityId: string;
      name: string;
    }[]
  >;
};

export const GET_COMMUNITY_DATA = gql`
  ${ALL_COMMUNITY_FEATURES_FRAGMENMT}
  query GetCommunityById($communityId: String!) {
    getCommunityById(communityId: $communityId) {
      success
      error
      data {
        styling {
          mainColor
          secondaryColor
          thirdColor
          hoverColor
          gradient
          gradientStart
          gradientEnd
          headerImage
          headerColor
          headerLogoHeight
          backgroundImage
          backgroundSize
          backgroundPosition
          backgroundImageWidth
          backgroundImageHeight
          superDarkGrey
          darkGrey
          mediumGrey
          lightGrey
          specialGrey
          sucessColor
          alertColor
          backgroundColor
          green
          red
          mode
        }
        communityId
        name
        stripeAccountId
        stripeFeeAdditional
        featuresConfig {
          ...AllCommunityFeatures
        }
        zendeskURLs {
          guest
          vendor
          resident
          host
          admin
        }
        zendeskWidgetSrc {
          guest
          vendor
          resident
          host
          admin
        }
      }
    }
  }
`;
export type GET_COMMUNITY_DATA_VARS = {
  communityId: string;
  __cfg_no_token?: boolean;
};

type stylingConfig = {
  mainColor: string;
  secondaryColor: string;
  thirdColor: string;
  hoverColor: string;
  gradient: string;
  gradientStart: string;
  gradientEnd: string;
  headerImage: string;
  headerColor: string;
  headerLogoHeight: string;
  backgroundImage: string;
  backgroundSize: string;
  backgroundPosition: string;
  backgroundImageWidth: string;
  backgroundImageHeight: string;
  superDarkGrey: string;
  darkGrey: string;
  mediumGrey: string;
  lightGrey: string;
  specialGrey: string;
  sucessColor: string;
  alertColor: string;
  backgroundColor: string;
  green: string;
  red: string;
  mode: string;
};

export type CommunityType = {
  styling?: stylingConfig;
  communityId: string;
  name: string;
  stripeAccountId?: string;
  stripeFeeAdditional?: boolean;
  featuresConfig?: CommuntiyConfigType;
  zendeskURLs?: {
    guest: string | null;
    vendor: string | null;
    resident: string | null;
    host: string | null;
    admin: string | null;
  };
  zendeskWidgetSrc?: {
    guest: string | null;
    vendor: string | null;
    resident: string | null;
    host: string | null;
    admin: string | null;
  };
  hostDocumentUploadConfig?: {
    specialInstructions: [] | null;
    instructionText: string | null;
  };
};

export type GET_COMMUNITY_DATA_RES = {
  getCommunityById: backendResponse<CommunityType[]>;
};

const GET_VEHICLE_TYPES = gql`
  query GetCommunityById($communityId: String!) {
    getCommunityById(communityId: $communityId) {
      success
      error
      data {
        vehicleTypes
      }
    }
  }
`;

export function useVehicleTypes() {
  const [vehicleTypeList, setVehicleTypeList] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const communityId = getCommunityId();

  useEffect(() => {
    async function fetchVehicleTypes() {
      if (!communityId) {
        setError('Community ID is not available');
        setIsLoading(false);
        return;
      }

      try {
        const response = await backendClient.query({
          query: GET_VEHICLE_TYPES,
          variables: {
            communityId: communityId,
          },
        });

        if (!response.data?.getCommunityById?.success) {
          throw new Error(response.data?.getCommunityById?.error || 'Unknown error occurred');
        }

        const vehicleTypes = response.data.getCommunityById.data[0]?.vehicleTypes;

        if (Array.isArray(vehicleTypes)) {
          setVehicleTypeList(vehicleTypes);
        } else {
          console.warn('Vehicle types is not an array');
          setVehicleTypeList([]);
        }
      } catch (err) {
        setError('Error fetching vehicle types: ' + (err instanceof Error ? err.message : String(err)));
      } finally {
        setIsLoading(false);
      }
    }

    fetchVehicleTypes();
  }, [communityId]);

  return { vehicleTypeList, isLoading, error };
}
