import React, {
  MutableRefObject,
  ReactElement,
  useContext,
  useEffect,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CommunityContext } from '../../communityConfigs/communityContextProvider';
import { portalOptions } from '../../types/portalOptions';

type ScormWrapperProps = {
  continueHandler: () => void;
  portal: portalOptions;
  src?: string;
  hidden?: boolean;
  loadFailureHandler?: () => void;
  className?: string;
};

function urlFactory(portal: string, communityId: string, lang?: string): string {
  let langCode = '';
  if (lang) {
    langCode = `_${lang}`;
  }
  return `https://symliv-educations.s3.us-east-2.amazonaws.com/${portal}/${communityId}${langCode}/story.html`;
}

function useScormUrl(props: {
  portal: string;
  ref: MutableRefObject<HTMLIFrameElement | null>;
  src?: string;
}): {
  loading: boolean;
  failure: boolean;
} {
  const { portal, ref, src } = props;

  const { communityId } = useContext(CommunityContext);
  const lang = useTranslation()[1].language;
  const cache = useRef<Record<string, boolean>>({});
  const [failure, setFailure] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  async function validateUrl(url: string): Promise<boolean> {
    const cacheVal = cache.current[url];
    if (cache.current[url] !== undefined) return cacheVal;
    return fetch(url)
      .then(res => {
        cache.current[url] = res.ok;
        return res.ok;
      })
      .catch(() => false);
  }

  const updateRef = useCallback(
    (url: string) => {
      if (ref.current) {
        ref.current.src = url;
      }
    },
    [ref],
  );

  const effectBody = useCallback(async () => {
    setFailure(false);
    setLoading(true);
    const urls = [] as string[];
    if (src) {
      urls.push(src);
    }
    urls.push(urlFactory(portal, communityId, lang));
    urls.push(urlFactory(portal, communityId));

    // eslint-disable-next-line no-restricted-syntax
    for (const testUrl of urls) {
      // eslint-disable-next-line no-await-in-loop
      const urlRes = await validateUrl(testUrl);
      if (urlRes) {
        updateRef(testUrl);
        setFailure(false);
        setLoading(false);
        return;
      }
    }
    setFailure(true);
    setLoading(false);
  }, [src, lang, communityId, portal, updateRef]);

  useEffect(() => {
    effectBody();
  }, [effectBody, src]);

  return {
    loading,
    failure,
  };
}

export default function ScormWrapper(props: ScormWrapperProps): ReactElement {
  const { src, continueHandler, portal, hidden, loadFailureHandler, className } = props;
  const scormRef = useRef<HTMLIFrameElement | null>(null);
  useScormUrl({ portal, src, ref: scormRef });

  useEffect(() => {
    const handler = (ev: MessageEvent) => {
      console.log('message captured');
      if (ev.data?.source === 'symliv-scorm' && ev.data?.message === 'complete') {
        console.log('message validated');
        continueHandler();
      }
    };
    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, [continueHandler]);

  return (
    <iframe
      ref={scormRef}
      title="Community Rules"
      className={className}
      allowFullScreen
      frameBorder="0"
      hidden={typeof hidden === 'boolean' && hidden}
      onError={e => {
        console.log(e);
        loadFailureHandler?.();
      }}
      onLoad={e => {
        console.log(e);
      }}
    />
  );
}
ScormWrapper.defaultProps = {
  src: undefined,
  className: undefined,
  hidden: false,
  loadFailureHandler: () => console.log("could not load :'("),
};
