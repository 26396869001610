import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import doValidate from '../../../common_lib_front/components/inviteGuestForm/useRentalValidation';
import PhoneInputWrapper from '../../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import useProperty from '../../../common_lib_front/hooks/useProperty';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';
import useVehicleConfigs from '../../../utils/useVehicleConfigs';
import style from './getPasses.module.css';

export const REGISTER_VEHICLE = gql`
  mutation SaveVehicle(
    $passId: String!
    $passInfoId: String!
    $vehicleInfo: ActuallyInputTypeVehicleInput!
    $propertySlug: String!
  ) {
    registerVehicleWithoutContext(
      passId: $passId
      passInfoId: $passInfoId
      vehicleInfo: $vehicleInfo
      propertySlug: $propertySlug
    ) {
      success
      error
    }
  }
`;

type editPassData = {
  color?: string;
  type?: string;
  licensePlate?: string;
};

export type REGISTER_VEHICLE_VARS = {
  passId: string;
  passInfoId: string;
  vehicleInfo: Partial<editPassData>;
};

export type REGISTER_VEHICLE_RES = {
  registerVehicle: backendResponse<undefined>;
};

function mutateWrapperForVehiclePass(
  data: REGISTER_VEHICLE_VARS,
): Promise<REGISTER_VEHICLE_RES> {
  return backendClient
    .mutate<REGISTER_VEHICLE_RES, REGISTER_VEHICLE_VARS>({
      mutation: REGISTER_VEHICLE,
      variables: data,
    })
    .then((res: any) => res.data);
}

export const INVITE_GUEST = gql`
  mutation Mutation($invites: [InviteGuestInput!]!, $propertySlug: String!) {
    inviteGuests(invites: $invites, propertySlug: $propertySlug) {
      error
      success
      data {
        registration {
          registrationId
        }
        passes {
          passId
          passInfoId
        }
      }
    }
  }
`;
export type INVITE_GUEST_VARS = {
  invites: {
    passes: Array<{
      startDate: string;
      endDate: string;
      passInfoId: string;
    }>;
    rentalInfo: {
      address: string;
      arrivalDate: string;
      departureDate: string;
    };
    guestInfo: {
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string;
    };
    hostWillPay?: boolean;
  }[];
  propertySlug: string;
};
export type INVITE_GUEST_RES = {
  inviteGuests: backendResponse<
    {
      registration?: {
        registrationId?: string;
      };
      passes?: {
        passId?: string;
        passInfoId?: string;
      }[];
    }[]
  >;
};


export function useInviteGuest() {
  const [doInviteGuest, { data, loading, error }] = useMutation<INVITE_GUEST_RES, INVITE_GUEST_VARS>(INVITE_GUEST);

  const inviteGuests = async (invites: INVITE_GUEST_VARS['invites'], propertySlug: INVITE_GUEST_VARS['propertySlug']) => {
    try {
      const response = await doInviteGuest({ variables: { invites, propertySlug } });
      return response.data;
    } catch (err) {
      console.error('Error inviting guests:', err);
      throw err;
    }
  };

  return {
    inviteGuests,
    data,
    loading,
    error,
  };
}

interface GetPassesData {
  firstName: string;
  email: string;
  phoneNumber: string;
  startDate: string;
  endDate: string;
  lastName: string;
}

interface VehiclePass {
  vehicleType: string;
  vehicleColor: string;
  licensePlate: string;
  dontKnowLicense: boolean;
}

type PassInfosWithVehicles = {
  [key: string]: VehiclePass[];
};

type PassWithVehicles = {
  passId: string;
  passInfoId: string;
  vehicles: VehiclePass[];
};

type VehicleData = {
  [key: string]: VehiclePass[];
};

type Pass = {
  passId: string;
  passInfoId: string;
};

interface VehiclePassFormProps {
  vehiclePass: VehiclePass;
  index: number;
  handleChange: (index: number, field: keyof VehiclePass, value: string) => void;
  handleCheckboxChange: (index: number) => void;
  smsAlert: boolean;
  promotionalAlert: boolean;
  setSmsAlert: (value: boolean) => void;
  setPromotionalAlert: (value: boolean) => void;
}

const VehiclePassForm: React.FC<VehiclePassFormProps> = ({ vehiclePass, index, handleChange, handleCheckboxChange }) => (
  <div className={style.vehiclePassForm}>
    <hr className={style.horizontalLine} />
    <h4 style={{ margin: '10px 0px 10px 0px' }}>Pass vehicle information</h4>
    <div style={{ display: 'flex', margin: '-35px 0px 0px 0px' }}>
      <div className={style.vehicleCheckboxContainer}>
        <input
          type="checkbox"
          checked={vehiclePass.dontKnowLicense}
          onChange={() => handleCheckboxChange(index)}
        />
        <span className={style.vehiclecheckboxText}>
          I dont know my license information right now.
        </span>
      </div>
      <div>
        <div className={style.vehicleOptions} >
          <label className={style.vehicleOptionslabel}>Vehicle type</label>
          <select
            required
            className={style.vehicleOptionsInput}
            value={vehiclePass.vehicleType}
            disabled={vehiclePass.dontKnowLicense}
            onChange={(e) => handleChange(index, 'vehicleType', e.target.value)}
          >
            <option value="">Choose a vehicle type</option>
            <option value="Coupe (2 Door Car)">Coupe (2 Door Car)</option>
            <option value="Sedan (4 Door Car)">Sedan (4 Door Car)</option>
            <option value="Sports Car">Sports Car</option>
            <option value="Wagon/Station Wagon">Wagon/Station Wagon</option>
            <option value="Sports Utility Vehicle (SUV)">Sports Utility Vehicle (SUV)</option>
            <option value="Minivan">Minivan</option>
            <option value="High Capacity Passenger Van">High Capacity Passenger Van</option>
            <option value="Pickup Truck">Pickup Truck</option>
            <option value="Bus">Bus</option>
            <option value="Utility Truck">Utility Truck</option>
            <option value="Other Comercial Vehicle (Box Truck/Semi)">Other Comercial Vehicle (Box Truck/Semi)</option>
            <option value="Trailer">Trailer</option>
            <option value="LSV">LSV</option>
            {/* Add more vehicle types as needed */}
          </select>
        </div>
        <div className={style.vehicleOptions} >
          <label className={style.vehicleOptionslabel}>Vehicle color</label>
          <input
            required
            className={style.vehicleOptionsInput}
            type="text"
            value={vehiclePass.vehicleColor}
            disabled={vehiclePass.dontKnowLicense}
            onChange={(e) => handleChange(index, 'vehicleColor', e.target.value)}
            placeholder="Enter vehicle color"
          />
        </div>
        <div className={style.vehicleOptions}>
          <label className={style.vehicleOptionslabel}>License plate</label>
          <input
            required
            className={style.vehicleOptionsInput}
            type="text"
            value={vehiclePass.licensePlate}
            disabled={vehiclePass.dontKnowLicense}
            onChange={(e) => handleChange(index, 'licensePlate', e.target.value)}
            placeholder='Enter your license plate number'
          />
        </div>
      </div>
    </div>
  </div>
);

export default function GetPasses(): ReactElement {
  const history = useHistory();
  const { propertySlug } = useParams<{ propertySlug: string }>();
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const [smsAlert, setSmsAlert] = useState<boolean>(false);
  const [promotionalAlert, setPromotionalAlert] = useState<boolean>(false);
  const { inviteGuests, data, loading:inviteLoading } = useInviteGuest();
  const [redirectPath, setRedirectPath] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const [getPassesData, setGetPassesData] = useState<GetPassesData>({
    firstName: '',
    email: '',
    phoneNumber: '',
    startDate: '',
    endDate: '',
    lastName: '',
  });
  const [guestInfo, setGuestInfo] = useState<any>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  });
  const [rentalInfo, setRentalInfo] = useState<any>({
    address: '',
    arrivalDate: '',
    departureDate: '',
  });
  const [passes, setPasses] = useState<any>([]);

  // State to hold vehicle passes for each passInfoId
  const [vehiclePassesByType, setVehiclePassesByType] = useState<{ [passInfoId: string]: VehiclePass[] }>({});
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);

  const { data: propertyDetail, loading: queryLoading, error:propertyError } = useProperty({ propertySlug: propertySlug || '' });
  const { passInfoDatas } = useVehicleConfigs();

  useEffect(() => {
    console.log('propertyDetail', propertyDetail);
    console.log('guestInfo', guestInfo);
    console.log('rentalInfo', rentalInfo);
    console.log('passes', passes);
    console.log('vehiclePassesByType', vehiclePassesByType);
  }, [passInfoDatas, guestInfo, rentalInfo, passes, vehiclePassesByType]);

  const handleChange = (field: keyof GetPassesData) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setGetPassesData({
      ...getPassesData,
      [field]: e.target.value,
    });
  };

  const handlePhoneChange = (phone: string) => {
    setGetPassesData({
      ...getPassesData,
      phoneNumber: phone,
    });
  };

  const handleVehicleChange = (passInfoId: string, index: number, field: keyof VehiclePass, value: string) => {
    setVehiclePassesByType(prevState => ({
      ...prevState,
      [passInfoId]: prevState[passInfoId]?.map((vehiclePass, i) =>
        i === index ? { ...vehiclePass, [field]: value } : vehiclePass
      ) || []
    }));
  };

  const handleCheckboxChange = (passInfoId: string, index: number) => {
    setVehiclePassesByType(prevState => {
      const updatedPasses = prevState[passInfoId]?.map((vehiclePass, i) => {
        if (i === index) {
          const updatedPass = { ...vehiclePass, dontKnowLicense: !vehiclePass.dontKnowLicense };
          if (updatedPass.dontKnowLicense) {
            updatedPass.licensePlate = '';
          }
          return updatedPass;
        }
        return vehiclePass;
      }) || [];

      return {
        ...prevState,
        [passInfoId]: updatedPasses
      };
    });
  };

  function mapPassIdsToVehicles(passIds: any[], passInfos: PassInfosWithVehicles): PassWithVehicles[] {
    const processedPassInfoIds = new Set<string>();
    const result: any[] = [];
    passIds.forEach(pass => {
      if (!processedPassInfoIds.has(pass.passInfoId)) {
        // Get the vehicles associated with the pass's passInfoId, or default to an empty array
        const vehicles = passInfos[pass.passInfoId] || [];

        // Filter vehicles to only include those where dontKnowLicense is false
        const filteredVehicles = vehicles.filter(vehicle => !vehicle.dontKnowLicense);

        // Only include the pass if there are filtered vehicles
        if (filteredVehicles.length > 0) {
          const vehicle = filteredVehicles[0]; // Select the first filtered vehicle

          result.push({
            passId: pass.passId,
            passInfoId: pass.passInfoId,
            vehicleInfo: {
                type: vehicle.vehicleType,
                licensePlate: vehicle.licensePlate,
                color: vehicle.vehicleColor,
                primaryDriverName: '',
                make: '',
            } as Partial<editPassData>,
            propertySlug: propertySlug,
          });

          // Mark this passInfoId as processed
          processedPassInfoIds.add(pass.passInfoId);
        }
      }
    });
    return result;
  }

  const addVehiclePass = (passInfoId: string) => {
    setVehiclePassesByType(prevState => ({
      ...prevState,
      [passInfoId]: [
        ...(prevState[passInfoId] || []),
        { vehicleType: '', vehicleColor: '', licensePlate: '', dontKnowLicense: false }
      ]
    }));

    if (getPassesData.startDate && getPassesData.endDate) {
      // Update passes state
      setPasses((prevState: any) => [
        ...prevState,
        { passInfoId, startDate: getPassesData.startDate, endDate: getPassesData.endDate }
      ]);
    }
  };

  const removeVehiclePass = (passInfoId: string) => {
    setVehiclePassesByType(prevState => {
      const updatedVehiclePasses = (prevState[passInfoId] || []).slice(0, -1);

      // Update passes state to remove the passInfoId if no vehicle passes are left
      setPasses((prevPasses: any) => {
        const updatedPasses = prevPasses.filter((p: any) => p.passInfoId !== passInfoId || updatedVehiclePasses.length > 0);

        return updatedPasses;
      });

      return {
        ...prevState,
        [passInfoId]: updatedVehiclePasses
      };
    });
  };

  function validateVehicleData(vehicleData: VehicleData): boolean {
    for (const key in vehicleData) {
      if (vehicleData.hasOwnProperty(key)) {
        const vehicles = vehicleData[key];
        for (const vehicle of vehicles) {
          if (!vehicle.dontKnowLicense) {
            if (
              !vehicle.vehicleType ||
              !vehicle.vehicleColor ||
              !vehicle.licensePlate
            ) {
              setErrorMessage('Please fill in all fields for each vehicle.');
              return false;
            }
          }
        }
      }
    }
    return true;
  }
  // Calculate total count and amount for each pass type
  const calculateTotals = () => {
    return passInfoDatas
      .filter(passInfoData => passInfoData.portal === 'guest')
      .map(passInfo => {
        const vehiclePasses = vehiclePassesByType[passInfo.passInfoId] || [];
        const totalCount = vehiclePasses.length;
        const passPrice = passInfo.price; // Make sure this field exists in passInfo
        const totalAmount = totalCount * passPrice; // Assuming $0.00/pass as mentioned, replace with actual amount if different

        return {
          passType: passInfo.name,
          totalCount,
          totalAmount
        };
      });
  };

  const aggregateTotals = () => {
    const totals = calculateTotals();

    // Initialize totals
    let totalCount = 0;
    let totalAmount = 0;

    // Aggregate totals from all pass types
    totals.forEach(({ totalCount: count, totalAmount: amount }) => {
      totalCount += count;
      totalAmount += amount;
    });

    return {
      totalCount,
      totalAmount
    };
  };

  // Synchronize guestInfo state with getPassesData state
  useEffect(() => {
    setGuestInfo({
      firstName: getPassesData.firstName,
      lastName: getPassesData.lastName,
      email: getPassesData.email,
      phoneNumber: getPassesData.phoneNumber,
    });

    setRentalInfo({
      address: propertyDetail?.address,
      arrivalDate: getPassesData.startDate,
      departureDate: getPassesData.endDate,
    });
  }, [getPassesData]);

  const totals = calculateTotals();
  const { totalCount, totalAmount } = aggregateTotals();
  const today = new Date().toISOString().split('T')[0]; // Today's date in YYYY-MM-DD format

  const handleContinue = async (e: any) => {
    e.preventDefault();
    if (totalCount === 0) {
      setIsButtonClicked(true);
      setErrorMessage('Please add at least one pass option.');
      return;
    }

    // Collect all vehicle pass data
    const updatedPasses = Object.keys(vehiclePassesByType).flatMap(passInfoId => {
      return vehiclePassesByType[passInfoId].map(vehiclePass => ({
        passInfoId,
        startDate: getPassesData.startDate,
        endDate: getPassesData.endDate,
        // ...vehiclePass
      }));
    });

    setPasses(updatedPasses);
    setErrorMessage('');
    // Handle form submission or navigation here
    const response = await validateVehicleData(vehiclePassesByType);
    if (response === true && passes.length > 0) {
      const validationRes = await doValidate({
        passesRentals: [
          {
            rentalInfo: {
              address: rentalInfo.address,
              arrivalDate: rentalInfo.arrivalDate,
              departureDate: rentalInfo.departureDate,
            },
            passes: passes.map((pass: any) => ({
              passInfoId: pass.passInfoId,
              endDate: pass.endDate,
              startDate: pass.startDate,
            })),
          }
        ],
      });

      const invites = [
        {
          guestInfo: {
            firstName: guestInfo.firstName,
            lastName: guestInfo.lastName,
            email: guestInfo.email,
            phoneNumber: guestInfo.phoneNumber,
          },
          hostWillPay: true,
          passes: passes.map((pass: any) => ({
            passInfoId: pass.passInfoId,
            endDate: pass.endDate,
            startDate: pass.startDate,
          })),
          rentalInfo: {
            address: rentalInfo.address,
            arrivalDate: rentalInfo.arrivalDate,
            departureDate: rentalInfo.departureDate,
          },
        }
      ];
      const result = await inviteGuests(invites, propertySlug);
      const data = result?.inviteGuests?.data ?? [];
      const pass = data.length > 0 ? data[0].passes ?? [] : [];
      const registrationId = data.length > 0 ? data[0].registration?.registrationId ?? '' : '';
      console.log('registrationId', registrationId);
      if (pass.length > 0) {
        const passesO = mapPassIdsToVehicles(pass, vehiclePassesByType);
        console.log({ passesO });
        passesO.forEach((pass: any) => {
          mutateWrapperForVehiclePass(pass);
        });
      }
      // redirect to next step Eduction
      const nextPageURL = `/guest/${propertySlug}/${registrationId}/education`;
      history.replace(nextPageURL);
    }
  };
  useEffect(() => {
    if (queryLoading || !propertySlug || !passInfoDatas) {
      setLoading(true);
      return;
    }
    if (propertyError) {
        setRedirectPath('/login');
        setLoading(false);
    } else {
        setLoading(false);
    }
  }, [propertyDetail, propertySlug, queryLoading, passInfoDatas, propertyError]);

  if (loading) {
    return (
      <div className={style.container}>
        <div className={style.box}>
          <div className={style.mainBox}>
            <div className={style.innerBox}>
              <div className={style.loading}></div>
              <p>Loading...</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <div className={style.container}>
      <div className={`${style.box} white`}>
        <div className={`${style.subHeader} mainColor`}>
          <div className={style.textBox}>
            <div className={`${style.circle} white`} />
            <h1 className={`${style.subHeaderTitle} textColorWhite`}>
              Review the community guidelines.
            </h1>
          </div>
        </div>
        <form onSubmit={handleContinue}>
          <div className={`${style.mainBox}`}>
            <div className={`${style.innerBox}`}>
              <div className={`${style.propertyDetail}`}>
                <div><h3>Property Details</h3></div>
                <div style={{ margin: '10px 0px 0px 0px' }}>{propertyDetail?.address}</div>
              </div>
            </div>
            <div className={style.alert}>
              <div className={style.alertBox}>
                <GenericAlert color={alertColor} title={alert} hidden={!alert} />
              </div>
            </div>

            <div className={style.guestDetailBox}>
              <div className={style.row}>
                <div className={style.box__inputField}>
                  <label htmlFor="firstName-inpt" className={style.label}>First Name <span style={{ color: 'red' }}>*</span></label>
                  <div className={style.inputBox}>
                    <InputField
                      required
                      smallSize
                      htmlFor="firstName-inpt"
                      inputType="text"
                      inputValue={getPassesData.firstName}
                      highlightOnFocus
                      changeHandler={handleChange('firstName')}
                      inputPlaceholder='Add your first name'
                    />
                  </div>
                </div>
                <div className={style.box__inputField}>
                  <label htmlFor="lastName-inpt" className={style.label}>Last Name <span style={{ color: 'red' }}>*</span></label>
                  <div className={style.inputBox}>
                    <InputField
                      required
                      smallSize
                      htmlFor="lastName-inpt"
                      inputType="text"
                      inputValue={getPassesData.lastName}
                      highlightOnFocus
                      changeHandler={handleChange('lastName')}
                      inputPlaceholder='Add your last name'
                    />
                  </div>
                </div>
              </div>
              <div className={style.box__inputField}>
                <label htmlFor="email-inpt" className={style.label}>Email Address <span style={{ color: 'red' }}>*</span></label>
                <div className={style.inputBox}>
                  <InputField
                    required
                    smallSize
                    htmlFor="email-inpt"
                    inputType="email"
                    inputValue={getPassesData.email}
                    highlightOnFocus
                    changeHandler={handleChange('email')}
                    inputPlaceholder='Enter your email address'
                  />
                </div>
              </div>
              <div className={style.box__inputField}>
                <label htmlFor="phone-inpt" className={style.label}>Phone Number <span style={{ color: 'red' }}>*</span></label>
                <div className={style.inputBox}>
                  <PhoneInputWrapper
                    value={getPassesData.phoneNumber}
                    changeHandler={handlePhoneChange}
                    smallStandardInput
                    required={true}
                    placeHolder='Enter your phone number'
                  />
                </div>
              </div>
              <div className={style.row}>
                <div className={style.box__inputField}>
                  <label htmlFor="check-in-inpt" className={style.label}>Check-in Date <span style={{ color: 'red' }}>*</span></label>
                  <div className={style.inputBox}>
                    <InputField
                      required
                      smallSize
                      htmlFor="startDate-inpt"
                      inputType="date"
                      inputValue={getPassesData.startDate}
                      highlightOnFocus
                      changeHandler={handleChange('startDate')}
                      inputPlaceholder="Choose the date"
                      min={today}
                    />
                  </div>
                </div>
                <div className={style.box__inputField}>
                  <label htmlFor="check-out-inpt" className={style.label}>Check-out Date <span style={{ color: 'red' }}>*</span></label>
                  <div className={style.inputBox}>
                    <InputField
                      required
                      smallSize
                      htmlFor="endDate-inpt"
                      inputType="date"
                      inputValue={getPassesData.endDate}
                      highlightOnFocus
                      changeHandler={handleChange('endDate')}
                      inputPlaceholder="Choose the date"
                      min={getPassesData.startDate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <h3 style={{ margin: '10px 10px 10px 20px' }}>Pass Options:</h3>
            {(isButtonClicked === true && totalCount === 0) || (errorMessage != '') && (
              <div className={style.errorContainer}>
                <p style={{ margin: '10px 10px 10px 20px', fontWeight: 'bold', color: 'red', fontSize: '15px' }} className={style.errorMessage}>Please add at least one pass option.</p>
              </div>
            )}
            {passInfoDatas
              .filter(passInfoData => passInfoData.portal === 'guest')
              .map(passInfo => {
                const passInfoId = passInfo.passInfoId;
                const vehiclePasses = vehiclePassesByType[passInfoId] || [];

                return (
                  <div key={passInfoId} className={`${style.mainInnerBox}`}>
                    <div className={style.innerBox}>
                      <div className={style.passOptions}>
                        <div className={style.passHeader}>
                          <h2>{passInfo.name}</h2>
                          <div className={style.row}>
                            <div>
                              <p style={{ margin: '10px 0px 0px 0px' }}>{passInfo.description}</p>
                            </div>
                            {/* <div className={style.passPrice}> */}
                            <div className={style.passCounter}>
                              <div className={style.priceContainer}>
                                <span className={style.price}>${passInfo.price}/pass</span>
                              </div>
                              <div className={style.counterContainer}>
                                <button className={style.counterButton} onClick={() => removeVehiclePass(passInfoId)} disabled={vehiclePasses.length <= 0}>-</button>
                                <span className={style.counter}>{vehiclePasses.length}</span>
                                <button className={style.counterButton} onClick={() => addVehiclePass(passInfoId)}>+</button>
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                        {vehiclePasses.map((vehiclePass, index) => (
                          <VehiclePassForm
                            key={index}
                            index={index}
                            vehiclePass={vehiclePass}
                            handleChange={(index, field, value) => handleVehicleChange(passInfoId, index, field, value)}
                            handleCheckboxChange={(index) => handleCheckboxChange(passInfoId, index)} smsAlert={false} promotionalAlert={false} setSmsAlert={function (value: boolean): void {
                              throw new Error('Function not implemented.');
                            }} setPromotionalAlert={function (value: boolean): void {
                              throw new Error('Function not implemented.');
                            }} />
                        ))}
                      </div>
                    </div>
                  </div>
                );
              })}
            <div className={style.innerBox}>
              <div style={{ margin: '20px 10px 10px 0px' }}>
                <h3>Subtotal ({totalCount} {totalCount > 1 ? 'passes' : 'pass'})</h3>
              </div>
              <div className={style.totals}>
                {totals.filter(totals => totals.totalCount > 0).map(total => (
                  <>
                    <div key={total.passType} className={style.totalBox}>
                      <div className={style.row} style={{ margin: '10px 10px 10px 0px' }}>
                        <p>({total.totalCount}) {total.passType}</p>
                        <p>${total.totalAmount.toFixed(2)}</p>
                      </div>
                    </div>
                  </>
                ))}
                <hr className={style.horizontalLine} />
                <div className={style.row} style={{ margin: '10px 10px 10px 0px' }}>
                  <h4>Total</h4>
                  <h4>${totalAmount.toFixed(2)}</h4>
                </div>
              </div>
            </div>
            <div className={style.checkboxContainer} style={{ marginTop: '50px' }}>
              <input
                type="checkbox"
                checked={smsAlert} // Add appropriate property for second checkbox
                onChange={() => setSmsAlert(!smsAlert)}
              />
              <span className={style.checkboxText}>
                Opt in to receive important SMS notifications about emergencies, safety updates, and critical community alerts. We&apos;ll keep you informed during any urgent situations.
              </span>
            </div>
            <div className={style.checkboxContainer}>
              <input
                type="checkbox"
                checked={promotionalAlert} // Add appropriate property for second checkbox
                onChange={() => setPromotionalAlert(!promotionalAlert)}
              />
              <span className={style.checkboxText}>
                By checking this box, you agree to receive SMS alerts about upcoming events, community news, and special promotions. Don&apos;t miss out on important updates from our community!
              </span>
            </div>
            <div className={style.innerBox}>
              <div className={style.buttonContainer}>
                <button className={style.button} onClick={handleContinue} type='submit'>
                  Continue
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}