import React, { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import GenericButton from '../../components/genericButton/genericButton';
import TitledStection from '../../components/titledSection/titledSection';

import style from './privacyPolicy.module.css';

export default function PrivacyPolicy(): ReactElement {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const history = useHistory();
  const highlightFirst = (elem: string) => {
    let [p, d] = ['', ''];
    if (elem.includes('|||')) {
      [p, d] = elem.split('|||');
    } else {
      const idx = elem.indexOf(' ');
      [p, d] = [elem.slice(0, idx), elem.slice(idx)];
    }
    return (
      <li key={p} className={`${style.listNoLine} textColorSuperDark`}>
        <strong className="textColorMain">{p}</strong>
        {d}
      </li>
    );
  };
  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <div className={`${style.subHeader} mainColor `}>
          <div className={style.textBox}>
            <div className={`${style.circle} white `} />
            <h1 className={`${style.subHeaderTitle} textColorWhite `}>Privacy Policy</h1>
          </div>
          <div className={style.backBtn}>
            <GenericButton
              color="yellow"
              size="large"
              // size="auto"
              title="Back"
              clickHandler={() => history.goBack()}
            />
          </div>
        </div>
        <div className={style.infoBox}>
          <div className={style.aboutBox}>
            <h3 className={style.update}>Last updated: July 19, 2021</h3>
            <p className={`${style.about} textColorSuperDark`}>
              This Privacy Policy describes Our policies and procedures on the collection,
              use and disclosure of Your information when You use the Service and tells
              You about Your privacy rights and how the law protects You. We use Your
              Personal data to provide and improve the Service. By using the Service, You
              agree to the collection and use of information in accordance with this
              Privacy Policy. The Company also includes provisions specific to the
              California Consumer Protection Act (“CCPA”) and how those provisions relate
              to California residents or “consumers’ as defined by the CCPA.
            </p>
          </div>
          <div>
            <h3 className={style.catelogue}>Catelogue:</h3>
            <ul className={style.catalogueBox}>
              {[
                'Interpretation and Definitions',
                'Collecting and Using Your Personal Data',
                'Use of Your Personal Data',
                'Retention of Your Personal Data',
                'Transfer of Your Personal Data',
                'Disclosure of Your Personal Data',
                'Security of Your Personal Data',
                "Children's Privacy",
                'Links to Other Websites',
                'Changes to this Privacy Policy',
                'Contact Us',
                'CALIFORNIA CONSUMER PRIVACY ACT – NOTICE',
                'Right to Know About Personal Information Collected, Disclosed, or Sold.',
                'Right to Request Deletion of Personal Information.',
                'Collection and Sources of Personal Information.',
                'Disclosure or Sale of Personal Information.',
                'Right to Opt-Out of the Sale of Personal Information.',
                'Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights.',
                'Authorized Agent.',
                'INFORMATION WE COLLECT ABOUT YOU',
                'INFORMATION YOU PROVIDE US (“PERSONAL INFORMATION”)',
                'INFORMATION WE RECEIVE FROM YOUR USE OF OUR SERVICES',
                'HOW WE USE YOUR INFORMATION',
                'PROVIDE AND MAINTAIN THE SERVICES',
                'IMPROVE, PERSONALIZE, AND DEVELOP THE SERVICES',
                'COMMUNICATE WITH YOU',
                'PROMOTE SAFETY AND SECURITY',
                'USE AND DISCLOSURE OF DE-IDENTIFIED INFORMATION',
              ].map((title: string) => (
                <li key={title} className={style.list}>
                  {title}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h2 className={style.title}>Interpretations and Definitions</h2>
            <h4 className={style.subtitle}>Interpretation</h4>
            <p className={`${style.text} textColorSuperDark`}>
              The words of which the initial letter is capitalized have meanings defined
              under the following conditions. The following definitions shall have the
              same meaning regardless of whether they appear in singular or in plural.
            </p>
            <h4 className={style.subtitle}>Definitions</h4>
            <p className={`${style.textBold} textColorSuperDark`}>
              For the purposes of this Privacy Policy:
            </p>
            <ul className={style.listBox}>
              {[
                'Account means a unique account created for You to access our Service or parts of our Service.',
                'Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to SymLiv, LLC, 69 N Paradise Parkway BLDG B STE 224 Garden City, UT 84028.',
                'Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.',
                'State and Country||| refers to: Utah and United States, respectively.',
                'Device means any device that can access the Service such as a computer, a cellphone, or a digital tablet..',
                'Personal Data is any information that relates to an identified or identifiable individual.',
                'Service refers to the Website.',
                'Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.',
                'Third-party Social Media Service refers to any website or any social network website through which a User can log in or create an account to use the Service.',
                'Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).',
                'Website refers to SymLiv, accessible through a custom SymLiv-provided URL. ',
                'You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.',
              ].map(highlightFirst)}
            </ul>
          </div>
          <div>
            <h2 className={style.title}>Collecting and Using Your Personal Data</h2>
            <h4 className={style.subtitle}>Types of Data Collected</h4>
            <h6 className={style.innerTitle}>Personal Data</h6>
            <p className={`${style.text} textColorSuperDark`}>
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or identify
              You. Personally identifiable information may include, but is not limited to:
            </p>
            <ul>
              {[
                { name: 'Email address', key: 'email' },
                { name: 'First name and last name', key: 'name' },
                { name: 'Phone number', key: 'phone-number' },
                {
                  name: 'Address, State, Province, ZIP/Postal code, City',
                  key: 'address',
                },
                { name: 'Usage Data', key: 'usage-data' },
              ].map((item: { name: string; key: string }) => (
                <li className={`${style.listNoLine} textColorSuperDark`} key={item.key}>
                  <strong className="textColorMain">{item.name}</strong>
                </li>
              ))}
            </ul>
            <h6 className={style.innerTitle}>Usage Data</h6>
            <p className={`${style.text} textColorSuperDark`}>
              Usage Data is collected automatically when using the Service.
              <br />
              Usage Data may include information such as Your Device&aos;s Internet
              Protocol address (e.g. IP address), browser type, browser version, the pages
              of our Service that You visit, the time and date of Your visit, the time
              spent on those pages, unique device identifiers and other diagnostic data.
              <br />
              When You access the Service by or through a mobile device, We may collect
              certain information automatically, including, but not limited to, the type
              of mobile device You use, Your mobile device unique ID, the IP address of
              Your mobile device, Your mobile operating system, the type of mobile
              Internet browser You use, unique device identifiers and other diagnostic
              data.
              <br />
              We may also collect information that Your browser sends whenever You visit
              our Service or when You access the Service by or through a mobile device.
            </p>
            <h6 className={style.innerTitle}>Tracking Technologies and Cookies</h6>
            <ul className={style.listBox}>
              <li className={`${style.listNoLine} textColorSuperDark`}>
                <strong className="textColorMain">Cookies or Browser Cookies.</strong>A
                cookie is a small file placed on Your Device. You can instruct Your
                browser to refuse all Cookies or to indicate when a Cookie is being sent.
                However, if You do not accept Cookies, You may not be able to use some
                parts of our Service. Unless you have adjusted Your browser setting so
                that it will refuse Cookies, our Service may use Cookies.
              </li>
              <li className={`${style.listNoLine} textColorSuperDark`}>
                <strong className="textColorMain">Flash Cookies.</strong>
                Certain features of our Service may use local stored objects (or Flash
                Cookies) to collect and store information about Your preferences or Your
                activity on our Service. Flash Cookies are not managed by the same browser
                settings as those used for Browser Cookies. For more information on how
                You can delete Flash Cookies, please read &quot;Where can I change the
                settings for disabling, or deleting local shared objects?&quot; available
                at
                <a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_">
                  https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where
                  _can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
                </a>
              </li>
              <li className={`${style.listNoLine} textColorSuperDark`}>
                <strong className="textColorMain">Web Beacons.</strong>
                Certain sections of our Service and our emails may contain small
                electronic files known as web beacons (also referred to as clear gifs,
                pixel tags, and single-pixel gifs) that permit the Company, for example,
                to count users who have visited those pages or opened an email and for
                other related website statistics (for example, recording the popularity of
                a certain section and verifying system and server integrity).
              </li>
            </ul>
            <p className={`${style.text} textColorSuperDark`}>
              Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies.
              Persistent Cookies remain on Your personal computer or mobile device when
              You go offline, while Session Cookies are deleted as soon as You close Your
              web browser. Learn more about cookies: What Are Cookies?.
            </p>
            <p className={`${style.textBold} textColorSuperDark`}>
              We use both Session and Persistent Cookies for the purposes set out below:
            </p>
            <ul className={style.listBox}>
              <li className={`${style.listNoLine} textColorSuperDark`}>
                <h4 className={`${style.listTitle} textColorMain`}>
                  Necessary / Essential Cookies
                </h4>
                Type: Session Cookies
                <br />
                Administered by: Us
                <br />
                Purpose: These Cookies are essential to provide You with services
                available through the Website and to enable You to use some of its
                features. They help to authenticate users and prevent fraudulent use of
                user accounts. Without these Cookies, the services that You have asked for
                cannot be provided, and We only use these Cookies to provide You with
                those services.
              </li>
              <li className={`${style.listNoLine} textColorSuperDark`}>
                <h4 className={`${style.listTitle} textColorMain`}>
                  Cookies Policy / Notice Acceptance Cookies
                </h4>
                Type: Persistent Cookies
                <br />
                Administered by: Us
                <br />
                Purpose: These Cookies identify if users have accepted the use of cookies
                on the Website.
              </li>
              <li className={`${style.listNoLine} textColorSuperDark`}>
                <h4 className={`${style.listTitle} textColorMain`}>
                  Functionality Cookies
                </h4>
                Type: Persistent Cookies
                <br />
                Administered by: Us
                <br />
                Purpose: These Cookies allow us to remember choices You make when You use
                the Website, such as remembering your login details or language
                preference. The purpose of these Cookies is to provide You with a more
                personal experience and to avoid You having to re-enter your preferences
                every time You use the Website.
              </li>
            </ul>
            <p className={`${style.text} textColorSuperDark`}>
              For more information about the cookies we use and your choices regarding
              cookies, please visit our Cookies Policy or the Cookies section of our
              Privacy Policy.
            </p>
          </div>
          <div>
            <h2 className={style.title}>Use of Your Personal Data</h2>
            <h6 className={`${style.textBold} textColorSuperDark`}>
              The Company may use Personal Data for the following purposes:
            </h6>
            <ul className={style.listBox}>
              {[
                'To provide and maintain our Service|||, including to monitor the usage of our Service.',
                'To manage Your Account|||: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.',
                'For the performance of a contract|||: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.',
                "To contact You|||: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
                'To provide You||| with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.',
                'To manage Your requests|||: To attend and manage Your requests to Us.',
                'For business transfers|||: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.',
                'For other purposes|||: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.',
              ].map(highlightFirst)}
            </ul>
            <h6 className={`${style.textBold} textColorSuperDark`}>
              We may share Your personal information in the following situations:
            </h6>
            <ul className={style.listBox}>
              {[
                'With Service Providers|||: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.',
                'For business transfers|||: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.',
                'With Affiliates|||: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us. ',
                'With other users|||: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.',
                'With Your consent|||: We may disclose Your personal information for any other purpose with Your consent.',
              ].map(highlightFirst)}
            </ul>
          </div>
          <div>
            <h2 className={style.title}>Retention of Your Personal Data</h2>
            <p className={`${style.text} textColorSuperDark`}>
              The Company will retain Your Personal Data only for as long as is necessary
              for the purposes set out in this Privacy Policy. We will retain and use Your
              Personal Data to the extent necessary to comply with our legal obligations
              (for example, if we are required to retain your data to comply with
              applicable laws), resolve disputes, and enforce our legal agreements and
              policies.
              <br />
              The Company will also retain Usage Data for internal analysis purposes.
              Usage Data is generally retained for a shorter period of time, except when
              this data is used to strengthen the security or to improve the functionality
              of Our Service, or We are legally obligated to retain this data for longer
              time periods.
            </p>
          </div>
          <TitledStection
            title="Transfer of Your Personal Data"
            textContent={[
              "Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country, or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.",
              'Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.',
              'The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.',
            ]}
          />
          <TitledStection
            title="Disclosure of Your Personal Data"
            subSections={[
              {
                h: 'Business Transactions',
                b: 'If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.',
              },
              {
                h: 'Law enforcement',
                b: 'Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).',
              },
            ]}
            listContent={[
              'Comply with a legal obligation',
              'Protect and defend the rights or property of the Company',
              'Prevent or investigate possible wrongdoing in connection with the Service',
              'Protect the personal safety of Users of the Service or the public',
              'Protect against legal liability',
            ]}
            listMapper={(elem: string) => (
              <li key={elem} className={`${style.listNoLine} textColorSuperDark`}>
                <strong className="textColorMain">{elem}</strong>
              </li>
            )}
          />
          <TitledStection
            title="Security of Your Personal Data"
            textContent={[
              'The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.',
            ]}
          />
          <TitledStection
            title="Children's Privacy"
            textContent={[
              'Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.',
              "If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.",
            ]}
          />
          <TitledStection
            title="Links to Other Websites"
            textContent={[
              'Our Services may contain links to other websites or services that are not owned or controlled by the Company, including links to websites of our sponsors and partners. This Privacy Policy only applies to information collected by our Services. We have no control over these third-party websites, and your use of third party websites and features are subject to privacy policies posted on those websites. We are not responsible or liable for the privacy or business practices of any third-party websites linked to our Services. Your use of third parties’ websites linked to our Services is at your own risk, so we encourage you to read the privacy policies of any linked third-party websites when you leave one of our Services.',
            ]}
          />
          <TitledStection
            title="Changes to this Privacy Policy"
            textContent={[
              'We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.',
              'We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.',
              'You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.',
            ]}
          />
          <TitledStection
            title="Contact Us"
            subSections={[
              {
                h: 'If you have any questions about this Privacy Policy, You can contact us:',
                b: (
                  <ul className={style.listBox}>
                    <li className={`${style.listNoLine} textColorSuperDark`}>
                      By visiting this page on our website:{' '}
                      <a href="https://symliv.com/contact-sales/">
                        https://symliv.com/contact-sales
                      </a>
                    </li>
                  </ul>
                ),
              },
            ]}
          />
          <TitledStection
            title="CALIFORNIA CONSUMER PRIVACY ACT – NOTICE"
            textContent={[
              'This Notice is to inform California residents or consumers about the personal information We collect or have collected over the past 12 months and to inform you that we may collect certain personal information about you as outlined in the Types of Personal Data section above and as provided in the Use of Personal Data section above.',
              'The following section provides additional details regarding the personal information we collect about California consumers and the rights granted to them under the California Consumer Privacy Act or “CCPA.”',
            ]}
          />
          <TitledStection
            title="Right to Know About Personal Information Collected, Disclosed, or Sold."
            listContent={[
              'A California consumer has the right to request that a business disclose what personal information it collects, uses, discloses, and sells. The Company does not sell Your personal information.',
              'If You are a California consumer and would like to know what personal information We collect, use, or disclose about You, then You can submit a verifiable consumer request by emailing Us at info@symliv.com.',
              'Before We can provide you the information You request, You are required under the CCPA to provide Us information that allows Us to verify Your request and to match information about You that We already have on file. For example, You may need to provide some or all of the following information: Your first and last name, email address, phone number, address, Your age, last four digits of Your SSN, and/or a signed declaration under penalty of perjury that You are the consumer whose personal information is the subject of the request. ',
            ]}
          />
          <TitledStection
            title="Right to Request Deletion of Personal Information."
            listContent={[
              'A California consumer has the right to request the deletion of his or her personal information collected or maintained by a business. However, if the personal information we have collected is necessary to provide You with Our business services then We cannot delete Your personal information.',
              'If You are a California consumer and would like to delete any personal information we have collected, used, or disclosed then you can submit a verifiable consumer request by emailing us at info@symliv.com.',
              'Before We can provide You the information You request, You are required under the CCPA to provide Us information that allows Us to verify Your request and to match information about You that We already have on file. For example You may need to provide some or all of the following information: Your first and last name, email address, phone number, address, Your age, last four digits of Your SSN, and/or a signed declaration under penalty of perjury that You are the consumer whose personal information is the subject of the request.',
            ]}
          />
          <TitledStection
            title="Collection and Sources of Personal Information."
            listContent={[
              'We have collected personal information based on the Categories of Personal Information section above.',
              'The sources of information that We collect come directly from You as a California consumer, or from Our clients who provide Us the information that they gathered from You in order for Us to provide You with the services We listed in the Use of Personal Data section above, or from third-party advertising sources that provide Us with information You initially provided to them.',
              'The categories of third parties with whom We share personal information are with Our clients and access control partners.',
            ]}
          />
          <TitledStection
            title="Disclosure or Sale of Personal Information."
            listContent={[
              'We have disclosed personal information to third parties in the preceding 12 months, but we have not sold any personal information in the preceding 12 months.',
              'We do not sell personal information, nor do We sell the personal information of minors under 16 years of age.',
              'The categories of personal information that We have disclosed to third parties for a business or commercial purpose in the preceding 12 months depends on the type of service provided and is outlined in the Types of Personal Data and the Uses of Personal Data sections above.',
            ]}
          />
          <TitledStection
            title="Right to Opt-Out of the Sale of Personal Information."
            listContent={[
              'California consumers have the right to opt-out of the sale of their personal information. However, we do not sell personal information.',
            ]}
          />
          <TitledStection
            title="Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights."
            listContent={[
              'California consumers have a right not to receive discriminatory treatment by the Company for the exercise of the privacy rights conferred by the CCPA.',
            ]}
          />
          <TitledStection
            title="Authorized Agent."
            listContent={[
              'A California consumer can designate an authorized agent to make a request under the CCPA on behalf of the consumer.',
            ]}
          />
          <TitledStection
            title="INFORMATION WE COLLECT ABOUT YOU"
            textContent={[
              'When you use our Services, We collect the following types of information.',
            ]}
            listMapper={(elem: string) => {
              const data: {
                t1: string;
                t2: string;
                t3: string;
                lc: Array<string>;
                e: string;
              } = JSON.parse(elem);
              return (
                <div>
                  <h2 className={style.specialTitle}>{data.t1}</h2>
                  <h4>
                    <strong className={style.titieInfoPart}>{data.t2}</strong>
                    <p className={`${style.textInfoPart} textColorSuperDark`}>
                      {data.t3}
                    </p>
                  </h4>
                  <ul className={style.listBox}>
                    {data.lc.map((l: string) => (
                      <li className={`${style.listNoLine} textColorMain`} key={l}>
                        {l}
                      </li>
                    ))}
                  </ul>
                  <p className={`${style.textInfoPart} textColorSuperDark`}>{data.e}</p>
                </div>
              );
            }}
            listContent={[
              JSON.stringify({
                t1: 'INFORMATION YOU PROVIDE US (“PERSONAL INFORMATION”)',
                t2: 'ACCOUNT INFORMATION.',
                t3: 'Some information is required to create an account on Services, such as your',
                lc: ['name,', 'email address,', 'password,', 'company,', 'phone number,'],
                e: '',
              }),
              JSON.stringify({
                t1: '',
                t2: 'ADDITIONAL INFORMATION.',
                t3: 'To help improve your experience or enable certain features of the Services, you may choose to provide us with additional information, such as',
                lc: [
                  'mailing address,',
                  'country information,',
                  'date of birth,',
                  'additional contact phone numbers such as your mobile telephone number,',
                ],
                e: 'If You contact us or participate in a survey, contest, or promotion, We collect the information You submit such as Your name, email address, contact information, and message.',
              }),
              JSON.stringify({
                t1: '',
                t2: 'INFORMATION FROM THIRD-PARTY SERVICES.',
                t3: 'If You choose to connect Your account on our Services to Your account on another service, We may receive information from the other service. For example, if You connect to Facebook or Google, we may receive information like Your name, profile picture, age range, language, email address and friend list. You may also choose to grant Us access to Your personal information such as activity data from other services. You can stop sharing the information from the other services with Us by removing Our access to each other service.',
                lc: [],
                e: '',
              }),
              JSON.stringify({
                t1: '',
                t2: 'INFORMATION PROVIDED BY OTHER INDIVIDUALS.',
                t3: 'While using Our Services, individuals may provide information about another individual, or an authorized user (such as an account administrator) creating an account on Your behalf may provide information about You. When one individual provides Us with information (including personal information) about another individual, We assume that the individual has permission and authority to do so and to consent on behalf of that individual to the collection and use of personal information as described in this Privacy Policy. Please contact us immediately if You become aware of an individual providing Us with personal information about another individual without being authorized to do so, and we will act consistently with this Privacy Policy.',
                lc: [],
                e: '',
              }),
              JSON.stringify({
                t1: '',
                t2: 'PAYMENT AND CARD INFORMATION.',
                t3: 'Some Services support payments and transactions with third parties. If You activate this feature, You must provide certain information for identification and verification, such as Your name, billing address, credit, debit or other card number, card expiration date and CVV code. This information is used solely to check Your financial qualifications and collect payment from you. We do not store your payment information. We use a third-party service provider to manage payment card processing. Note that third-party payment processors may retain this information in accordance with their own privacy policies and terms. This service provider is not permitted to store, retain or use information You provide except for the sole purpose of credit card processing on our behalf.',
                lc: [],
                e: '',
              }),
            ]}
          />
          <TitledStection
            title="INFORMATION WE RECEIVE FROM YOUR USE OF OUR SERVICES"
            listMapper={(elem: string) => {
              const data: {
                t: string;
                b: string;
              } = JSON.parse(elem);
              return (
                <div>
                  <p className={`${style.text} textColorSuperDark `}>
                    <strong className={style.titieInfoPart}>{data.t}</strong>
                    {data.b}
                  </p>
                </div>
              );
            }}
            listContent={[
              JSON.stringify({
                t: 'USAGE AND DEVICE INFORMATION.',
                b: 'When You use our Services, We receive certain usage data (“Usage and Device Information”). This includes information about Your interaction with the Services, for example, when You view or search content, install or open applications or software, create or log into Your account, import data into Your account, or integrate a third-party service to Your account. We may also collect data about the devices and computers You use to access our Services, including IP addresses, browser type, language, operating system, or mobile device information (including device and application identifiers), the referring web page, pages visited, location (depending on the permissions You have granted Us), and cookie information.',
              }),
            ]}
          />
          <TitledStection
            title="HOW WE USE YOUR INFORMATION"
            textContent={[
              'We use the information we collect for the following purposes.',
            ]}
          />
          <TitledStection
            title="PROVIDE AND MAINTAIN THE SERVICES"
            textContent={[
              'We use the information We collect to deliver the Services to You and honor Our Terms and Conditions for each Service or business contract with You. For example,',
            ]}
            listContent={[
              'to administer, operate, maintain and secure Our Services;',
              'to monitor and analyze trends, usage and activities in connection with Our Services;',
              'for accounting, recordkeeping, backup and administrative purposes;',
              'to customize and improve the content of our communications, websites and social media accounts;',
              'to provide customer service and support;',
              'to communicate with You, including responding to Your comments, questions and requests regarding Our Services;',
              'to process and complete transactions, and send You related information, including alerts and notifications about Your service, purchase confirmations and invoices; ',
              'to educate and train Our workforce in data protection and customer support.',
            ]}
          />
          <TitledStection
            title="IMPROVE, PERSONALIZE, AND DEVELOP THE SERVICES"
            textContent={[
              'We use the information We collect to improve and personalize the Services and to develop new ones. For example, We use the information to troubleshoot and protect against errors; perform data analysis and testing; conduct research and surveys and develop new features and Services.',
            ]}
          />
          <TitledStection
            title="COMMUNICATE WITH YOU"
            textContent={[
              'We use Your information when needed to send You Service notifications and respond to You when You contact us. We also use Your information to promote new features or products that We think You would be interested in. You can control marketing communications and most Service notifications by using Your notification preferences in account settings or via the “Unsubscribe” link in an email.',
            ]}
          />
          <TitledStection
            title="PROMOTE SAFETY AND SECURITY"
            textContent={[
              'We use the information We collect to promote the safety and security of the Services, Our users and other parties. For example, we may use the information',
            ]}
            listContent={[
              'to authenticate users;',
              'to facilitate secure payments;',
              'to respond to a legal request or claim, conduct audits, and enforce our terms and policies;',
              'to investigate and protect against fraud, malicious or unauthorized access, and other illegal activities;',
              'to demonstrate and verify compliance with our internal policies and procedures, and applicable privacy and data security laws and regulations, such as CCPA and GDPR.',
            ]}
          />
          <TitledStection
            title="USE AND DISCLOSURE OF DE-IDENTIFIED INFORMATION"
            textContent={[
              '“De-identified” means that we have removed, or rendered unreadable through complex computational algorithms, your personally-identifiable information, such as your name, address, or birthdate. We may use de-identified information that We have obtained from Our Services for various purposes, including for example:',
            ]}
            listContent={[
              'In accordance with regulatory requirements, We may de-identify, store and use Your information for internal quality control, validation and research and development. This is important for the Company to maintain high quality Services. We may use de-identified information as permitted by law.',
              'We may use or disclose de-identified information for general research and communications purposes. This may include analysis of this information to communicate observations and learnings, for example in the case of aggregated data. This may also include research collaborations with third parties, such as universities, in which we utilize de-identified data, at the individual level or in the aggregate, in accordance with our study protocols, and We may present or publish such information.',
            ]}
          />
          <p className={`${style.text} textColorSuperDark `}>
            For personal data subject to the GDPR/CCPA, we rely on several legal bases to
            process the data. These include when You have given Your consent, which You
            may withdraw at any time using Your account settings and/or other tools; when
            the processing is necessary to perform a contract with You, like the Terms of
            Service; and our legitimate business interests, such as in improving,
            personalizing, and developing the Services, marketing new features or products
            that may be of interest, and promoting safety and security as described above.
          </p>
        </div>
      </div>
    </div>
  );
}
