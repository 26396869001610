import { InfoConfigValues } from './InfoConfigValues';

export type VehicleInfo = {
  vehicleId: string;
  make: string;
  model: string;
  type: string;
  color: string;
  plateNumber: string;
  fleetNumber: string;
  licensePlateState: string;
  primaryDriverName: string;
  licensePrimaryDriver: string;
  isRental: boolean;
  destination: string;
  year?: number;
  insuranceDocUrl?: string;
  registrationDocUrl?: string;
  name?: string;
  employeeList?: string[];
  employeeId?: string;
  insDocFileName?: string;
  regDocFileName?: string;
  identificationSource?: string;
  employeeSelectList?: string[];
  [key: string]: any;
};
export type VehicleInfoConfig = {
  [Property in keyof Omit<VehicleInfo, 'vehicleId'>]: keyof typeof InfoConfigValues;
} & {
  completeLater?: keyof typeof InfoConfigValues;
  vehicleSelect?: keyof typeof InfoConfigValues;
  employeeSelect?: keyof typeof InfoConfigValues;
};

export function newVehicleInfo(data: Partial<VehicleInfo> = {}): VehicleInfo {
  const res: VehicleInfo = {
    vehicleId: data?.vehicleId || '',
    make: data?.make || '',
    model: data?.model || data?.vehicleModel || '',
    type: data?.type || '',
    color: data?.color || '',
    plateNumber: data?.plateNumber || data?.licensePlate || '',
    fleetNumber: data?.fleetNumber || '',
    licensePlateState: data?.licensePlateState || '',
    primaryDriverName: data?.primaryDriverName || '',
    licensePrimaryDriver: data?.licensePrimaryDriver || '',
    isRental: data?.isRental || false,
    year: data?.year,
    destination: data?.destination || '',
    insuranceDocUrl: data?.insuranceDocUrl,
    registrationDocUrl: data?.registrationDocUrl,
    insDocFileName: data?.insDocFileName || '',
    regDocFileName: data?.regDocFileName || '',
    name: data?.name || '',
    employeeList: data?.employeeList,
    employeeId: data?.employeeId,
    identificationSource: data?.identificationSource,
    employeeSelectList: data?.employeeSelectList,
  };
  // Object.assign(res, data);
  return res;
}

export function newVehicleInfoConfig(
  data: Partial<VehicleInfoConfig> = {},
): VehicleInfoConfig {
  return {
    make: data?.make || 'required',
    model: data?.model || data?.vehicleModel || 'required',
    type: data?.type || 'required',
    color: data?.color || 'required',
    plateNumber: data?.plateNumber || data?.licensePlate || 'required',
    fleetNumber: data?.fleetNumber || 'disabled',
    licensePlateState: data?.licensePlateState || 'disabled',
    primaryDriverName: data?.primaryDriverName || 'disabled',
    licensePrimaryDriver: data?.licensePrimaryDriver || 'disabled',
    isRental: data?.isRental || 'disabled',
    name: data?.name || 'disabled',
    completeLater: data?.completeLater || 'disabled',
    employeeList: data?.employeeList || 'disabled',
    destination: data?.destination || 'disabled',
    employeeSelect: data?.employeeSelect || 'disabled',
    vehicleSelect: data?.vehicleSelect || 'disabled',
  };
}

export default VehicleInfo;
