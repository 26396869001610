import { gql, useMutation } from '@apollo/client';
import { createPaymentSession } from '../../hooks/usePaymentSession';

const CHARGE_CUSTOMER = gql`
  mutation ChargeCustomer(
    $userId: String!,
    $paymentMethodType: String!,
    $paymentSessionId: String!) {
    chargeCustomer(
    userId: $userId,
    paymentMethodType: $paymentMethodType,
    paymentSessionId: $paymentSessionId
    ) {
      success
      error
    }
  }
`;

const GET_PAYMENT_METHOD = gql`
  mutation GetPaymentMethod($userId: String!) {
    getPaymentMethod(userId: $userId) {
      success
      data {
        type
      }
      error
    }
  }
`;

const ATTACH_PAYMENT_METHOD = gql`
  mutation AttachPaymentMethod($paymentMethodId: String!, $customerId: String!, $userId: String!) {
    attachPaymentMethod(paymentMethodId: $paymentMethodId, customerId: $customerId, userId: $userId) {
      success
      error
    }
  }
`;


const useChargeCustomer = () => {
  const [chargeCustomer, { data, loading, error }] = useMutation(CHARGE_CUSTOMER);
  const [callPaymentMethod] = useMutation(GET_PAYMENT_METHOD);
  const [attachPaymentMethod] = useMutation(ATTACH_PAYMENT_METHOD);

  const charge = async (
    userId: string,
    amount: number,
    paymentMethodId?: string,
    stripeCustomerId?: string,
    isHostLink?: boolean,
    isGuestLink?: boolean,
    registrationId?: string,
  ) => {
    try {
      if (isGuestLink) {
        const attachResponse = await attachPaymentMethod({
          variables: {
            paymentMethodId,
            customerId: stripeCustomerId,
            userId,
          },
        });
      }

      // if (isHostLink) {
      const paymentResponse = await callPaymentMethod({
        variables: { userId },
      });
      if (registrationId) {
        // call payment session 
        const paymentSessionDetails = await createPaymentSession({
          newPaymentSession: {
            registrationIds: [registrationId],
          },
          userId: userId,
          guestFlow: true,
        });
        if (
          paymentResponse.data?.getPaymentMethod.success &&
          paymentSessionDetails.data?.createPaymentSession.data?.paymentSessionId
          ) {
          const response = await chargeCustomer({
            variables: {
              userId,
              paymentMethodType: paymentResponse.data?.getPaymentMethod?.data?.type,
              paymentSessionId: paymentSessionDetails.data?.createPaymentSession.data?.paymentSessionId,
            },
          });
          if (response.data?.chargeCustomer.success) {
            return response;
          } else {
            console.error('Payment failed:', response.data?.chargeCustomer.error);
          }
        } else {
          console.error(
            'Failed to get payment method:',
            paymentResponse.data?.getPaymentMethod.error,
          );
        }
      }
      // }
    } catch (e) {
      console.error('Mutation error:', e);
    }
  };

  return { charge, data, loading, error };
};

export default useChargeCustomer;
