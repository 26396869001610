import { ApolloQueryResult, gql } from '@apollo/client';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';

export const VALIDATE = gql`
  query Query($passesRentals: [ValidatorInput!]!) {
    bulkValidate(passesRentals: $passesRentals) {
      data
      error
      success
    }
  }
`;
export type VALIDATE_VARS = {
  passesRentals: {
    passes: {
      passInfoId: string;
      endDate: string;
      startDate: string;
    }[];
    rentalInfo: {
      address: string;
      arrivalDate: string;
      departureDate: string;
    };
  }[];
};
export type VALIDATE_RES = {
  bulkValidate: backendResponse<string[]>;
};

export default function doValidate(
  variables: VALIDATE_VARS,
): Promise<ApolloQueryResult<VALIDATE_RES>> {
  return backendClient.query<VALIDATE_RES, VALIDATE_VARS>({
    query: VALIDATE,
    fetchPolicy: 'network-only',
    variables,
  });
}
