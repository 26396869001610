import { gql, useLazyQuery, useQuery } from '@apollo/client';

export interface GridColumn {
  aggFunc?: string | null;
  colId?: string;
  flex?: number | null;
  hide?: boolean;
  pinned?: boolean | string | null;
  pivot?: boolean;
  pivotIndex?: number | null;
  rowGroup?: boolean | null;
  rowGroupIndex?: boolean;
  sort?: string | null;
  sortIndex?: number | null;
  width?: number | null;
}

export interface GridSetting {
  gridName: string;
  columns: GridColumn[];
}

export interface GridSettings {
  grids: GridSetting[];
}

export interface GridColumnInput {
  aggFunc: string | null;
  colId: string;
  flex: string;
  hide: boolean;
  pinned: boolean | string | null;
  pivot: boolean;
  pivotIndex: number | null;
  rowGroup: boolean | null;
  rowGroupIndex: boolean;
  sort: string | null;
  sortIndex: number | null;
  width: number | null;
}

export interface GridSettingsInput {
  gridName: string;
  columns: GridColumnInput[];
}

export type User = {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  isLoggedIn: boolean;
  phoneNumber: string;
  emergencyPhone: string;
  login: string;
  avatarUrl: string;
  roles: string[];
  gridSettings: GridSettings;
};

const GET_USER_INFO_QUERY = gql`
  query GetUserInfo {
    getUserInfo {
      success
      data {
        userId
        gridSettings {
          grids {
            gridName
            columns {
              aggFunc
              colId
              flex
              hide
              pinned
              pivot
              pivotIndex
              rowGroup
              rowGroupIndex
              sort
              sortIndex
              width
            }
          }
        }
      }
      error
    }
  }
`;
export const useUsers = () => {
  const [getUserInfo, { loading, client, data }] = useLazyQuery(GET_USER_INFO_QUERY);

  const getCurrentUserInformation = async (): Promise<User> => {
    const userResp = (await getUserInfo()).refetch();
    return (await userResp).data.getUserInfo?.data;
  };

  return {
    getCurrentUserInformation,
  };
};
